import React from 'react';
import { Heading, HStack, Link, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import "./style.css";
import { CityPicker, NavMenu } from "./children";
import getLink from "../../../getLink";

export function Header(props) {
    return <motion.div
        className="menuBar"
        id='topHeader'
        initial={ props.type === 'animate' ? { paddingTop: "13%", scale: 2 } : { paddingTop: "0px", scale: 1 }} 
        animate={ props.type === 'animate' ? { paddingTop: "0px", scale: 1 } : ''}
        transition={{ delay: 0.5, duration: 1, ease: "easeInOut" }}
    >
        <VStack w="100%" pos='fixed' zIndex={1000} >
            <HStack w="100%" justify="space-between" px="8vw" py="3vh">
                <NavMenu />
                <Link href={getLink("/")} _hover={{ textDecoration: "none" }}><Heading color='white' fontSize='60px' fontFamily='AquireBold'>Kladu Pech</Heading></Link>
                <CityPicker city={props.city} />
            </HStack>

            <motion.hr
                style={{ color: "white", width: "100%" }}
                initial={{ width: "0px", opacity: 0 }}
                animate={ props.type === 'animate' ? { width: "100%", opacity: 1 } : '' }
                transition={{ delay: 1.5, duration: 1.5, ease: "easeInOut" }} />
        </VStack>
    </motion.div>
}