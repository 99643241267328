import React from 'react';
import { Heading, HStack, Link } from '@chakra-ui/react';
import './style.css';
import { NavMenu } from './children/NavMenu';
import getLink from '../../../getLink';

export function Header(props) {
    return <HStack w="100%" p="30px 10px 30px 10px" className="menuBar" borderBottom="1px solid white" justify="space-evenly">
        <NavMenu city={props.city} />
        <Link href={getLink('/')} _hover={{ textDecoration: 'none' }}><Heading color="white" fontSize="30px" fontFamily="AquireBold" textAlign="center">Kladu Pech</Heading></Link>
    </HStack>
}