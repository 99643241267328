import { Box, VStack, Image, Text } from "@chakra-ui/react";

export function Bricks(props) {
    return <VStack spacing='100px' w='90%' fontFamily='Jost' ref={props.sref}>
        <Box w="100%" p='0'>
            <Text color="white" fontSize="30px" float='left'>ТИПЫ И ВИДЫ КИРПИЧЕЙ, КОТОРЫЕ МЫ ИСПОЛЬЗУЕМ</Text>
        </Box>
        <VStack>
            <Box bg='gray.500' borderRadius='full' filter='blur(40px)' w='250px' h='200px' mt='0px' pos='relative' zIndex={0}></Box>
            <Box>
                <VStack mt='-185px' pos='relative' zIndex={1} spacing='50px'>
                <Image src={require("../../../img/bricks/bricks1.webp")} />
                    <Text color='white' fontSize={24} textAlign='left' w='100%'>Красный кирпич – это наиболее часто применяемый материал, из этого материала можно выкладывать стены, перегородки и даже печи. Для стен может использоваться обычный пустотелый, а для кладки кирпичной печи бани мы используем термостойкий марки М75 или М150.</Text>
                </VStack>
            </Box>
        </VStack>
        <VStack>
            <Box bg='gray.500' borderRadius='full' filter='blur(40px)' w='250px' h='200px' mt='0px' pos='relative' zIndex={0}></Box>
            <Box>
                <VStack mt='-185px' pos='relative' zIndex={1} spacing='50px'>
                <Image src={require("../../../img/bricks/bricks3.webp")} />
                    <Text color='white' fontSize={24} textAlign='left' w='100%'>Шамотный кирпич – относится к огнеупорным материалам, изготавливается на основе шамотной глины. В бане применяется для кладки топки печей, для обустройства экранов в деревянных банях и банях с металлическими печами.</Text>
                </VStack>
            </Box>
        </VStack>
        <VStack>
            <Box bg='gray.500' borderRadius='full' filter='blur(40px)' w='250px' h='200px' mt='0px' pos='relative' zIndex={0}></Box>
            <Box>
                <VStack mt='-185px' pos='relative' zIndex={1} spacing='50px'>
                <Image src={require("../../../img/bricks/bricks5.webp")} />
                    <Text color='white' fontSize={24} textAlign='left' w='100%'>Красный кирпич – это наиболее часто применяемый материал, из этого материала можно выкладывать стены, перегородки и даже печи. Для стен может использоваться обычный пустотелый, а для кладки кирпичной печи бани мы используем термостойкий марки М75 или М150.</Text>
                </VStack>
            </Box>
        </VStack>
        <VStack>
            <Box bg='gray.500' borderRadius='full' filter='blur(40px)' w='250px' h='200px' mt='0px' pos='relative' zIndex={0}></Box>
            <Box>
                <VStack mt='-185px' pos='relative' zIndex={1} spacing='50px'>
                <Image src={require("../../../img/bricks/bricks4.webp")} />
                    <Text color='white' fontSize={24} textAlign='left' w='100%'>Силикатный кирпич для обустройства бани не подходит, так как он впитывает влагу и может использоваться исключительно внешней для облицовки.</Text>
                </VStack>
            </Box>
        </VStack>
    </VStack>
}