import React from 'react';
import {
    VStack, Text, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Box, Table, Th, Tr, Tfoot, Td, Thead, Tbody, TableContainer, Center, HStack
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import getLink from "../../getLink";
import { ChevronRightIcon, ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { Bricks, Footer, ThirdForm } from '../../components/Mobile';

export default function PricePage(props) {
    return <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
    >
        <VStack w='100%' h='100%' mt='-70px' fontFamily='Jost' color='white' bg='#141416' spacing="50px" pb='100px'>
            <Box w='90%' maxW='1200px'>
                <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='white' />} mt='30px'>
                    <BreadcrumbItem>
                        <BreadcrumbLink href={getLink("/")} color='white' fontSize={25} transition="0.5s" _hover={{ textDecoration: "none", color: "gray.400", transition: "0.5s" }}>Главная</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href='#' color='white' transition="0.5s" fontSize={20} _hover={{ textDecoration: "none", color: "gray.400", transition: "0.5s" }}>Наши расценки</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <Text color="white" fontSize={35} textAlign='center'>Наши расценки</Text>
                <Text color="white" fontSize={20} textAlign='left' mt='20px'>Во время работы мы используем только высококачественные материлы и лучших специалистов. Здесь представлена таблица цен на наши услуги, материалы и отдельно взятые работы.<br /><span style={{ fontSize: '16px' }} >Конечно, в зависимости от ваших потребностей и стоимости материалов на рынке стоимость может изменять(подробности узнавайте у менеджера).</span></Text>
                <Center><HStack mb='20px' spacing='-7px' ><ArrowBackIcon w='20px' h='20px' /><ArrowForwardIcon w='20px' h='20px' /></HStack></Center>
                <Center>
                    <TableContainer mt='30px' w='90%' >
                        <Table variant='striped' size='md' colorScheme='whiteAlpha' color='white' >
                            <Thead>
                                <Tr>
                                    <Th>УСЛУГИ</Th>
                                    <Th>КОЛ. ДНЕЙ НА ВЫПОЛНЕНИЕ РАБОТЫ</Th>
                                    <Th isNumeric>СТОИМОСТЬ РАБОТЫ</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>Печь с плитой и сушилкой</Td>
                                    <Td>≈ 12 дн.</Td>
                                    <Td isNumeric>≈ 110 000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Экономичная печка для отопления<br /> помещений</Td>
                                    <Td>≈ 13 дн.</Td>
                                    <Td isNumeric>	≈ 115 000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Русская печь</Td>
                                    <Td>≈ 18 дн.</Td>
                                    <Td isNumeric>	≈ 140000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Печь отопительная декорированная</Td>
                                    <Td>≈ 13 дн.</Td>
                                    <Td isNumeric>	≈ 119000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Закрытый камин с дверкой</Td>
                                    <Td>≈ 12-16 дн.</Td>
                                    <Td isNumeric>	≈ 145000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Камин облицованный камнем</Td>
                                    <Td>≈ 13-18 дн.</Td>
                                    <Td isNumeric>≈ 75000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Камин с чугунной топкой</Td>
                                    <Td>≈ 9-14 дн.</Td>
                                    <Td isNumeric>	≈ 99000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Углой мангал со столиком из кирпича</Td>
                                    <Td>≈ 9-12 дн.</Td>
                                    <Td isNumeric>	≈ 99000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Барбекю-комплекс с плитой-духовкой</Td>
                                    <Td>≈ 20 дн.</Td>
                                    <Td isNumeric>	≈ 199000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Угловой барбекю-комплекс из пустотелого кирпича</Td>
                                    <Td>≈ 10-15 дн.</Td>
                                    <Td isNumeric>	≈ 199000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Барбекю с плитой из кирпича ручной формовки</Td>
                                    <Td>≈ 15-20 дн.</Td>
                                    <Td isNumeric>	≈ 149000 руб.</Td>
                                </Tr>
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    <Th>УСЛУГИ</Th>
                                    <Th>КОЛ. ДНЕЙ НА ВЫПОЛНЕНИЕ РАБОТЫ</Th>
                                    <Th isNumeric>СТОИМОСТЬ РАБОТЫ</Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </TableContainer>
                </Center>
                <Text color="white" fontSize={40} mt='50px' textAlign='center'>РЕМОНТНЫЕ РАБОТА</Text>
                <Center><HStack mt='20px' spacing='-7px' ><ArrowBackIcon w='20px' h='20px' /><ArrowForwardIcon w='20px' h='20px' /></HStack></Center>
                <Center>
                    <TableContainer mt='5px' w='90%' mb='40px' >
                        <Table size='md' variant='striped' colorScheme='whiteAlpha' color='white' >
                            <Thead>
                                <Tr>
                                    <Th>УСЛУГИ</Th>
                                    <Th>КОЛ. ДНЕЙ НА ВЫПОЛНЕНИЕ РАБОТЫ</Th>
                                    <Th isNumeric>СТОИМОСТЬ РАБОТЫ</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>Устранение дефекта при износе уплотнителей печи</Td>
                                    <Td>≈ 1-5 дн.</Td>
                                    <Td isNumeric>≈ 10000 - 55000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Устранение дефекта при ослаблении тяги печи</Td>
                                    <Td>≈ 1-5 дн.</Td>
                                    <Td isNumeric>≈ 10000 - 65000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Устранение дефекта при разгерметизации печи</Td>
                                    <Td>≈ 1-5 дн.</Td>
                                    <Td isNumeric>≈ 10000 - 70000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Устранение дефекта при засорении трубы печи</Td>
                                    <Td>≈ 1-5 дн.</Td>
                                    <Td isNumeric>≈ 10000 - 65000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Устранение дефекта при образовании конденсата в печи</Td>
                                    <Td>≈ 1-5 дн.</Td>
                                    <Td isNumeric>≈ 10000 - 40000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Устранение дефекта при завываниях ветра в трубе печи</Td>
                                    <Td>≈ 1-5 дн.</Td>
                                    <Td isNumeric>≈ 10000 - 65000 руб.</Td>
                                </Tr>
                                <Tr>
                                    <Td>Устранение дефекта при быстром остывании печи</Td>
                                    <Td>≈ 1-5 дн.</Td>
                                    <Td isNumeric>≈ 10000 - 65000 руб.</Td>
                                </Tr>
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    <Th>УСЛУГИ</Th>
                                    <Th>КОЛ. ДНЕЙ НА ВЫПОЛНЕНИЕ РАБОТЫ</Th>
                                    <Th isNumeric>СТОИМОСТЬ РАБОТЫ</Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </TableContainer>
                </Center>
                <Center><Bricks /></Center>
            </Box>
            <ThirdForm />
            <Footer />
        </VStack>
    </motion.div>
}