import React from "react";
import { PhoneIcon, ChatIcon } from "@chakra-ui/icons";
import { Box, Center, Input, Text, Button, InputGroup, InputRightElement, VStack, useToast } from "@chakra-ui/react";
import axios from "axios";

export function SecondForm(props) {

    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const toast = useToast();

    const sendForm = () => {

        if (name.length < 2 || phone.length < 10) toast({
            position: 'bottom-right',
            title: `Введите корректные данные!`,
            status: 'warning',
            isClosable: true,
            containerStyle: {
                width: '350px'
            }
        }); else {
            toast({
                position: 'bottom-right',
                title: `Заявка отправлена!`,
                status: 'success',
                isClosable: true,
                containerStyle: {
                    width: '300px'
                }
            });

            axios.post('https://api.twodev.cc/helper/pechi', {
                city: props.city.text ? props.city.text : props.city,
                info: ``,
                name: name,
                phone: phone
            });
            
            window.ym(92057640, 'reachGoal', ' send_form_complited')
            console.log('форма метрика')

            setName("");
            setPhone("");
        };
    };


    const infoButtonStyles = {
        backgroundColor: "#D0493E",
        color: "white",
        borderRadius: "44px",
        width: "200px",
        height: "50px",
        transition: "0.3s"
    };

    const infoButtonStylesHover = {
        marginTop: "1px",
        filter: "drop-shadow(0px 10px 20px #E15757)",
        backgroundColor: "#bd4934",
        cursor: "pointer"
    };

    return <Box w='100%' fontFamily='Jost' ref={props.sref}>
        <Center>
            <Box w='85%' bg='white' borderRadius={20} px='20px' py='30px' >
                <Box >
                    <Text fontSize={30} >
                        Не нашли то, что искали?
                    </Text>
                    <Text fontSize={22} >
                        Оставьте заявку на сайте и мы подготовим проект по запросу и построим качественно и в кратчайшие сроки!
                    </Text>
                    <Box w='100%' >
                        <VStack mt='15px' spacing='10px'>
                            <InputGroup>
                                <InputRightElement
                                    pointerEvents='none'
                                    children={<ChatIcon color='gray.300' ml='-14px' />}
                                />
                                <Input onChange={setName} placeholder="Как к Вам обращаться?" />
                            </InputGroup>
                            <InputGroup>
                                <InputRightElement
                                    pointerEvents='none'
                                    children={<PhoneIcon color='gray.300' ml='-10px' />}
                                />
                                <Input onChange={setPhone} placeholder="Ваш номер телефона" />
                            </InputGroup>
                            <Box><Button style={infoButtonStyles} _hover={infoButtonStylesHover} mt='10px' onClick={sendForm} >ОФОРМИТЬ</Button></Box>
                        </VStack>
                    </Box>
                </Box>
            </Box>
        </Center>
    </Box>
}