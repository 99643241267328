import { Box, VStack, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from "@chakra-ui/react";

export function Questions() {
    return <VStack w='85%' spacing='50px' >
        <Box w="100%" p='0'><Text color="white" fontSize="30px" float='left' ml='0px'>ЧАСТО ЗАДАВАЕМЫЕ<br /> ВОПРОСЫ - ОТВЕТЫ</Text></Box>
        <Box w='95%' >
            <Accordion allowToggle color='white' fontFamily='Jost' >
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left' fontSize='32px' >
                                Что самое главное в печи?
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} fontSize='20px'  >
                        Все главное. Если что-то сделаешь неправильно, то печь не будет работать как надо. На функциональную работу печи, может повлиять даже расположение дома или печи в доме, поэтому в этом вопросе надо быть максимально внимательным и проверить каждый нюанс.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left' fontSize='32px' >
                                В какое время года и при какой температуре можно строить камин?
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} fontSize='20px'>
                        Конечно работать приятней летом в хорошую погоду. Но для печных работ наиболее важны условия на объекте. Их можно создать в любое время года. Необходимо просто соблюдать технологию при работах. Для этого на объекте создают необходимую температуру с помощью нагревателей различного типа.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left' fontSize='32px' >
                                Сколько весит печь?
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} fontSize='20px'  >
                        Часто заказчики да же не представляют сколько же может весить печь и неправильно рассчитывают фундамент, например свайный. И когда мы объявляем вес печи, то вы очень удивляетесь. Безусловно вес печи варируется в зависимости от того какой тип печи, какой кирпич вы хотите использовать, но говоря о цифрах, то стандартная кирпичная печь весит от 4700 кг.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left' fontSize='32px' >
                                Сколько времени занимает строительство кирпичной печи?
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} fontSize='20px'  >
                        Сколько времени занимает строительство печи из кирпича - вопрос сложный. Здесь очень много факторов, влияющих на скорость работ. Мы выполняем заказ из 1000 кирпичей приблизительно 1 календарный месяц, в зависимости от занятости печника и сложности заказа. Бывает быстрее и за две недели, но бывает и дольше.
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>
    </VStack>
}