import {
    Box, Center, VStack, Text, HStack, Icon
} from "@chakra-ui/react";
import { BsFileLock, BsClockHistory, BsThermometerHalf } from "react-icons/bs";
import { SiSpeedtest } from "react-icons/si";
import { AiOutlineFire } from "react-icons/ai";
import { IoCalendarOutline } from "react-icons/io5";

export function Advantages(props) {
    return <Box w='100%' id='Advantages' fontFamily='Jost' ref={props.sref}>
        <Center>
            <Box w='70%'>
                <VStack spacing='100px' >
                    <Box w='100%'><Text color="white" fontSize="35px" w='500px' float='left'> ВАШИ ДОСТИЖЕНИЯ ПРИ РАБОТЕ С НАМИ</Text></Box>
                    <Box>
                        <VStack gap='40px' pos='relative' zIndex={1} >
                            <HStack gap='80px' >
                                <VStack gap='5px'>
                                    <Icon as={BsFileLock} color='#D0493E' w='70px' h='70px' ml='-40px' />
                                    <Text fontSize={24} color='white' w='300px'>БЕЗОПАСНОСТЬ</Text>
                                    <Text fontSize={20} color='white' w='300px'>пока вы работаете с нами, вы уверены, что все безопасно </Text>
                                </VStack>

                                <VStack gap='5px'>
                                    <Icon as={BsClockHistory} color='#D0493E' w='70px' h='70px' />
                                    <Text fontSize={24} color='white' w='300px'>СКОРОСТЬ</Text>
                                    <Text fontSize={20} color='white' w='300px' >наша компания выложит вам печь в кратчайшие сроки</Text>
                                </VStack>
                                <VStack gap='5px'>
                                    <Icon as={SiSpeedtest} color='#D0493E' w='70px' h='70px' ml='-40px' />
                                    <Text fontSize={24} color='white' w='300px'>ПРОФЕССИОНАЛИЗМ</Text>
                                    <Text fontSize={20} color='white' w='300px' >наши специалисты работы быстро и качественно </Text>
                                </VStack>
                            </HStack>
                            <HStack gap='80px' >
                                <VStack gap='5px'>
                                    <Icon as={AiOutlineFire} color='#D0493E' w='70px' h='70px' ml='-40px' />
                                    <Text fontSize={24} color='white' w='300px'>УЮТ</Text>
                                    <Text fontSize={20} color='white' w='300px' >печь это не только тепло, но и уют с эстетикой</Text>
                                </VStack>
                                <VStack gap='5px'>
                                    <Icon as={BsThermometerHalf} color='#D0493E' w='70px' h='70px' />
                                    <Text fontSize={24} color='white' w='300px'>ТЕПЛО</Text>
                                    <Text fontSize={20} color='white' w='300px' >ваша печь будет сохранять тепло в доме до 24 часов</Text>
                                </VStack>
                                <VStack gap='5px'>
                                    <Icon as={IoCalendarOutline} color='#D0493E' w='70px' h='70px' ml='-40px' />
                                    <Text fontSize={24} color='white' w='300px'>ДОЛГОВЕЧНОСТЬ</Text>
                                    <Text fontSize={20} color='white' w='300px' >печи и камины прослужат долго без необходимости ремонта</Text>
                                </VStack>
                            </HStack>
                        </VStack>
                    </Box>
                </VStack>
                <Box bg='gray.500' mt='-370px' w='850px' ml='70px' h='400px' borderRadius='110px' filter='blur(40px)' opacity='0.7' pos='relative' zIndex={0} />
            </Box>
        </Center>

    </Box>
}