import { Box, Center, Text, Image, VStack } from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export function CarouselBlock(props) {

    return <Box w='100%' fontFamily="Jost" ref={props.sref} >
        <Center>
            <VStack w='80%' spacing='20px'>
                <Box w='100%'><Text color="white" fontSize="35px" float='left'>НАШИ ВЫПОЛНЕННЫЕ РАБОТЫ</Text></Box>
                <Box>
                    <Carousel showStatus={false} transitionTime={700} showThumbs={false} autoPlay={true} infiniteLoop={true} onClickItem={x => console.log(x)}>
                        <Box gap='5px'>
                            <Image src={require("../../../img/imgCarousel/1.webp")} w='200px' h='375px' />
                        </Box>
                        <Box gap='5px'><Image src={require("../../../img/imgCarousel/2.webp")} w='200px' h='375px' /></Box>
                        <Box gap='5px'><Image src={require("../../../img/imgCarousel/8.webp")} w='200px' h='375px' /></Box>
                        <Box gap='5px'><Image src={require("../../../img/imgCarousel/4.webp")} w='200px' h='375px' /></Box>
                        <Box gap='5px'><Image src={require("../../../img/imgCarousel/5.webp")} w='200px' h='375px' /></Box>
                        <Box gap='5px'><Image src={require("../../../img/imgCarousel/6.webp")} w='200px' h='375px' /></Box>
                        <Box gap='5px'><Image src={require("../../../img/imgCarousel/3.webp")} w='200px' h='375px' /></Box>
                        <Box gap='5px'><Image src={require("../../../img/imgCarousel/7.webp")} w='200px' h='375px' /></Box>

                    </Carousel>
                </Box>
            </VStack>
        </Center>

    </Box >
}