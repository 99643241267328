import React, { useState } from 'react';
import { Text, Box, VStack, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Image, FormControl, Input, Center, HStack, Icon } from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons';
// import bgmp4 from "../../../img/Back.mp4";
import "./style.css";
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { AiFillCheckCircle } from "react-icons/ai";

export function Interesting(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [op, setOp] = React.useState(0);
    const [op2, setOp2] = React.useState(0);
    const [sent, setSent] = useState(false);

    function handleSend() {
        setSent(true);
        axios.post('https://api.twodev.cc/helper/pechi', {
            city: props.city.text ? props.city.text : props.city,
            info: ``,
            name: name,
            phone: phone
        });
        window.ym(92057640, 'reachGoal', ' send_form_complited')
        console.log('форма метрика')
    }

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    
    return <VStack fontFamily="Jost" gap='20px' id="Int" ref={props.sref}>
        <Box w='82%'><Text color="white" fontSize="20px">НАШИ УСЛУГИ ПЕЧНИКОВ ДЛЯ ВАС В {(props.city.text2 ? props.city.text2 : props.city).toUpperCase()}</Text></Box>

        <Box w='82%'>
            <VStack gap='30px'>
                <VStack gap='20px'>
                    <Box flexDirection="column" alignItems="flex-start" justifyContent="space-between" bg="#D0493E"
                        borderRadius="30px 70px 70px 30px" p="20px 20px 24px 24px" h='350px'>
                        <Text fontSize="28px" color="white">РАБОТАЕМ БОЛЕЕ 10 ЛЕТ</Text>
                        <Text fontSize="24px" color="white" h='160px' mt='20px'>Наша компания предоставляет услуги печников по всей России с 2012 года</Text>
                        <Button
                            bg='white'
                            color='#D0493E'
                            borderRadius='44px'
                            w='200px'
                            h='50px'
                            transition='1s'
                            _hover={{ bg: 'white', cursor: 'pointer', filter: 'drop-shadow(0px 10px 20px white)', transition: '1s', mt: '2px' }}
                            onClick={onOpen}
                        >
                            ЧИТАТЬ</Button>
                    </Box>
                    <Box bg="white" borderRadius="30px" p="20px 20px 24px 24px" h='340px' w='100%' > 
                        <Text fontSize="28px" color="#D0493E">ДИЗАЙН</Text>
                        <Text fontSize="24px" color="black" h='195px' w='93%' mt='20px'>У нас работают лучшие специалисты в отрасли дизайна, которые составят проект Вашей мечты</Text>
                        <Button
                            bg='#D0493E'
                            color='white'
                            borderRadius='44px'
                            w='200px'
                            h='50px'
                            transition='1s'
                            _hover={{ bg: '#bd4934', cursor: 'pointer', filter: 'drop-shadow(0px 10px 20px #E15757)', transition: '1s', mt: '2px' }}
                            onClick={() => setOp(1)}
                        >
                            ЗАКАЗАТЬ</Button>
                    </Box>
                </VStack>

                <VStack gap='30px'>
                    <Box bg="white" borderRadius="30px" p="20px 10px 24px 24px" w='100%' h='230px'>
                        <Text fontSize="28px" color="#D0493E">ПРЕИМУЩЕСТВА</Text>
                        <Text fontSize="24px" color="black" mt='10px'>Все преимущества работы с нами</Text>
                        <Button
                            bg='#D0493E'
                            color='white'
                            borderRadius='44px'
                            w='200px'
                            h='50px'
                            transition='1s'
                            mt='15px'
                            _hover={{ bg: '#bd4934', cursor: 'pointer', filter: 'drop-shadow(0px 10px 20px #E15757)', transition: '1s', mt: '47px' }}
                            onClick={() => document.getElementById('Advantages').scrollIntoView({ behavior: 'smooth', block: 'end' })}
                        >
                            УЗНАТЬ</Button>
                    </Box>

                    <Box bg="white" borderRadius="30px" p="20px 20px 24px 24px" w='100%' h='230px'>
                        <Text fontSize="28px" color="#D0493E">ГАРАНТИИ</Text>
                        <Text fontSize="24px" color="black">В нашей компании все надёжно и прозрачно</Text>
                        <Button
                            bg='#D0493E'
                            color='white'
                            borderRadius='44px'
                            w='200px'
                            h='50px'
                            transition='1s'
                            mt='20px'
                            _hover={{ bg: '#bd4934', cursor: 'pointer', filter: 'drop-shadow(0px 10px 20px #E15757)', transition: '1s', mt: '22px' }}
                            onClick={() => setOp2(1)}
                        >
                            ПОДРОБНЕЕ</Button>
                    </Box>
                </VStack>
            </VStack>
        </Box>
        <Modal isOpen={isOpen} onClose={onClose} size='4xl'>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader fontSize={25} >НАША КОМПАНИЯ НА РЫНКЕ С 2012 ГОДА</ModalHeader>
                <ModalCloseButton />
                <ModalBody fontFamily='Jost' >
                    <Text fontSize={20} >Компания "Кладу печь" на рынку более 10 лет и мы уже имеем непокалебимый авторитет и бесценный опыт. Наша компания предоставляет услуги печников по всей стране, начиная с европейского Калининграда и заканчивая далёким Владивостоком! Печники нашей команды мастера своего дела. Каждый из них занимается кладкой печей, каминов, барбекю комплексов не один год и каждый проходит ежегодное повышение квалификации. <br /><br />Для того чтобы получился качественный продукт, работник должен любить то дело, которым заниматся. Работая с нами, вы можете не сомневаться, в том что печь получится на высоком уровне и прослужит вам долгие годы теплой, душой и верностью!  </Text>
                    <Box w='100%' borderTop='solid 1.5px gray' mt='5px' opacity='0.3' />
                    <Image mt='20px' src={require("../../../img/mod1.webp")} />
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='gray' mr={3} onClick={onClose}>
                        Закрыть
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
        <Modal isOpen={op === 1}>
            <ModalOverlay />
            <ModalContent fontFamily='Jost' >
                <ModalHeader fontFamily={22} >Оформление заявки</ModalHeader>
                <ModalCloseButton onClick={() => setOp(0)} />
                <ModalBody pb={6} fontFamily='Jost'>
                    <AnimatePresence mode="wait">
                        <motion.div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column"
                            }}
                            key={sent}
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.4 }}
                        >
                            {sent
                                ? <Icon as={AiFillCheckCircle} color="green" transform="scale(3)" />
                                : <>
                                    <Text mb="20px">После оформления заявки наш менеджер свяжется с Вами!</Text>
                                    <FormControl>
                                        <Input placeholder="Как к вам обращаться?" onChange={setName} />
                                    </FormControl>

                                    <FormControl mt={4}>
                                        <Input placeholder="Ваш номер телефона" onChange={setPhone} />
                                    </FormControl>
                                </>
                            }
                        </motion.div>
                    </AnimatePresence>
                </ModalBody>

                <ModalFooter>
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={sent}
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.4 }}
                        >
                            {sent
                                ? <></>
                                : <HStack>
                                        <Button colorScheme='red' w='180px' p='25px' borderRadius={20} leftIcon={<AddIcon />} onClick={handleSend}>Оставить заявку</Button>
                                        <Button colorScheme='gray' w='140px' p='25px' borderRadius={20} ml='10px' onClick={onClose}>Закрыть</Button>                                  
                                </HStack>
                            }
                        </motion.div>
                    </AnimatePresence>
                </ModalFooter>
            </ModalContent>
        </Modal>
        <Modal isOpen={op2 === 1} size='2xl' >
            <ModalOverlay />
            <ModalContent fontFamily='Jost' >
                <ModalHeader fontFamily={22} >Гарантии и Безопасность</ModalHeader>
                <ModalCloseButton onClick={() => setOp2(0)} />
                <ModalBody >
                    <Text fontSize={20} >Компания "Кладу печь" на рынку более 10 лет и не один клиент не был обманут. Мы работаем по принципу договор - работа. Сначала обсуждаем все ваши желания, затем составляем проект, согласовываем нюансы, подписываем договор и только потом начинаем работу! Поэтому вы можете быть уверены, что ваши деньги не пропадут, а работа будет выполнены качествено и в срок. Будем рады нашему сотрудничеству!</Text>
                    <Box w='100%' borderTop='solid 1.5px gray' mt='5px' opacity='0.3' />
                    <Center><Image h='450px' src={require("../../../img/mod2.webp")} /></Center>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='gray' mr={3} onClick={() => setOp2(0)} >
                        Закрыть
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
        
    </VStack>
}