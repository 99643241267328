import React from 'react';
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, HStack, Input, Link, List, ListItem, Text, useDisclosure } from "@chakra-ui/react";
import cities from "../../../../cities.json";
import { ChevronDownIcon } from "@chakra-ui/icons";

const finalAll = [];
Object.values(cities).filter(c => c.cities).map(c => Object.entries(c.cities).map(x => (finalAll[x[0]] = x[1])));

export function CityPicker(props) {
    const [selectedOblast, setSelectedOblast] = React.useState("moskovskaya-oblast");
    const [cityFilter, setCityFilter] = React.useState(null);

    const { isOpen: isOpenTop, onOpen: onOpenTop, onClose: onCloseTop } = useDisclosure();
    const btnRefTop = React.useRef();

    return <>
        <Button
            ref={btnRefTop}
            onClick={onOpenTop}
            bg='none'
            color="white"
            fontSize="24px"
            p="0px"
            w="100px"
            rightIcon={<ChevronDownIcon />}
            _hover={{ background: "none", color: "gray" }}
            _active={{ background: "none", color: "gray" }}
        >
            {props.city.text ? props.city.text : props.city}
        </Button>

        <Drawer
            isOpen={isOpenTop}
            placement='top'
            onClose={onCloseTop}
            finalFocusRef={btnRefTop}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Выбор города</DrawerHeader>

                <DrawerBody>
                    <Input placeholder='Найти город...' onChange={event => setCityFilter(event.target.value)} />
                    <HStack w='100%' mt='20px' align="start">
                        <Box w='50%'>
                            <Text fontSize={40} color='gray'>Регионы</Text>
                            <List>
                                {cityFilter
                                    ? Object.entries(cities).filter(c => c[1].text.toLowerCase().includes(cityFilter.toLowerCase())).map(c => <ListItem
                                        key={c[0]}
                                        style={{ borderLeft: selectedOblast === c[0] ? "4px solid orange" : "0px solid white", padding: "6px 4px 6px 8px", fontSize: "17px" }}
                                        _hover={{ cursor: "pointer", borderLeft: "4px solid red", bgColor: "gray.100" }}
                                        onClick={() => setSelectedOblast(c[0])}>
                                        {c[1].text}
                                    </ListItem>)
                                    : Object.entries(cities).map(c =>
                                        <ListItem
                                            key={c[0]}
                                            style={{ borderLeft: selectedOblast === c[0] ? "4px solid orange" : "0px solid white", padding: "6px 4px 6px 8px", fontSize: "17px" }}
                                            _hover={{ cursor: "pointer", borderLeft: "4px solid red", bgColor: "gray.100" }}
                                            onClick={() => setSelectedOblast(c[0])}>
                                            {c[1].text}
                                        </ListItem>
                                    )}
                            </List>
                        </Box>
                        <Box borderLeftWidth='1px' pl='20px'>
                            <Text fontSize={40} color='gray'>Города</Text>
                            <List>
                                {cityFilter
                                    ? Object.entries(finalAll).filter(c => (typeof c[1] === "string" ? c[1] : c[1].text).toLowerCase().includes(cityFilter.toLowerCase())).map(x =>
                                        <Link href={`/${x[0]}`} style={{ textDecoration: "none" }}>
                                            <ListItem
                                                key={x[0]}
                                                style={{ borderLeft: selectedOblast === x[0] ? "4px solid orange" : "0px solid white", padding: "6px 4px 6px 8px", fontSize: "17px" }}
                                                _hover={{ cursor: "pointer", borderLeft: "4px solid red", bgColor: "gray.100" }}>
                                                {typeof x[1] === "string" ? x[1] : x[1].text}
                                            </ListItem>
                                        </Link>
                                    )
                                    : Object.entries(cities[selectedOblast].cities).map(x =>
                                        <Link href={`/${x[0]}`} style={{ textDecoration: "none" }}>
                                            <ListItem
                                                key={x[0]}
                                                style={{ borderLeft: selectedOblast === x[0] ? "4px solid orange" : "0px solid white", padding: "6px 4px 6px 8px", fontSize: "17px" }}
                                                _hover={{ cursor: "pointer", borderLeft: "4px solid red", bgColor: "gray.100" }}>
                                                {typeof x[1] === "string" ? x[1] : x[1].text}
                                            </ListItem>
                                        </Link>
                                    )}
                            </List>
                        </Box>
                    </HStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    </>
}
