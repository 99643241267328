import {
    Box, Center, VStack, Text, HStack, Button, Icon, Link, Image
} from '@chakra-ui/react';
import { FaTelegram, FaWhatsapp } from 'react-icons/fa';
import { AiFillMail } from 'react-icons/ai';
import getLink from '../../../getLink';
import Phone from '../../Phone';

export function Footer(props) {
    return <VStack w="100%" fontFamily="Jost" bg="#141416" pt="16px">
        <Box h="200px" w="100%">
            <Center>
                <HStack w="70%" spacing="100px" justify="space-between">
                    <VStack>
                        <Text color="gray.400" fontSize={22} textAlign="center">НАВИГАЦИЯ</Text>
                        <HStack spacing="50px" w="max-content">
                            <VStack>
                                <Link href={getLink('/bannie')} color="gray.500" fontWeight="bold" fontSize={20} _hover={{ color: 'white', transition: '1s' }}>Банные печи</Link>
                                <Link href={getLink('/barbeque')} color="gray.500" fontWeight="bold" fontSize={20} _hover={{ color: 'white', transition: '1s' }}>Комплекс барбекю</Link>
                                <Link href={getLink('/kaminopechi')} color="gray.500" fontWeight="bold" fontSize={20} _hover={{ color: 'white', transition: '1s' }}>Камино-печи</Link>
                                <Link href={getLink('/kamini')} color="gray.500" fontWeight="bold" fontSize={20} _hover={{ color: 'white', transition: '1s' }}>Камины</Link>
                            </VStack>
                            <VStack>
                                <Link onClick={() => document.getElementById('Catalog').scrollIntoView({ behavior: 'smooth', block: 'end' })} color="gray.500" fontWeight="bold" fontSize={20} _hover={{ color: 'white', transition: '1s' }}>Печи</Link>
                                <Link onClick={() => document.getElementById('Contact').scrollIntoView({ behavior: 'smooth', block: 'center' })} color="gray.500" fontWeight="bold" fontSize={20} _hover={{ color: 'white', transition: '1s' }}>Контакты</Link>
                                <Link onClick={() => document.getElementById('Contact').scrollIntoView({ behavior: 'smooth', block: 'center' })} color="gray.500" fontWeight="bold" fontSize={20} _hover={{ color: 'white', transition: '1s' }}>Оставить заявку</Link>
                                <Link href={getLink('/')} color="gray.500" fontWeight="bold" fontSize={20} _hover={{ color: 'white', transition: '1s' }}>Главная</Link>
                            </VStack>
                        </HStack>
                    </VStack>
                    <Box w="50%" h="100%">
                        <Text textAlign="center" color="gray.300" fontSize={21}>
                            Проффесиональная кладка банных печей, каминов, комплексов барбекю, русских печей и множества другого!
                        </Text>
                    </Box>
                    <Box w="200px">
                        <Text color="gray.400" fontSize={22} textAlign="center" ml="0px">КОНТАКТЫ</Text>
                        <HStack gap="30px">
                            <VStack mt="15px">
                                <Phone city={props.city} />
                                <Link href="mailto:kladu-pech@mail.ru" _hover={{ textDecoration: 'none' }} onClick={() => {
                                    window.ym(92057640, 'reachGoal', ' mail_message');
                                    console.log('Цель метрики на нажатие телефона');
                                }}><Button variant="link" fontSize={20} mt="7px" color='white' _hover={{ color: 'white', transition: '1s' }}
                                >kladu-pech@mail.ru</Button></Link>
                                <HStack ml="-130px" mt="-20px">
                                    <a href="tg://resolve?domain=kladuPech" className="big"
                                        onClick={() => {
                                            window.ym(92057640, 'reachGoal', ' telegram_message')
                                            console.log('Цель метрики на нажатие Telegram');
                                        }}><Icon as={FaTelegram} w="35px" h="35px" color="gray.400" _hover={{ color: 'white', transition: '1s' }} /></a>
                                    <a href="https://wa.me/+79876543210" className="big"
                                        onClick={() => {
                                            window.ym(92057640, 'reachGoal', ' whatsapp_message')
                                            console.log('Цель метрики на нажатие WhatsApp');
                                        }}><Icon as={FaWhatsapp} w="35px" h="35px" transition="1s" color="gray.400" _hover={{ color: 'white', transition: '1s' }} /></a>
                                    <a href="mailto:kladu-pech@mail.ru" className="big"
                                        onClick={() => {
                                            window.ym(92057640, 'reachGoal', ' mail_message')
                                            console.log('Цель метрики на нажатие почту');
                                        }}><Icon as={AiFillMail} w="35px" h="35px" color="gray.400" _hover={{ color: 'white', transition: '1s' }} /></a>
                                </HStack>
                            </VStack>
                        </HStack>
                    </Box>
                </HStack>
            </Center>
        </Box>
        <HStack justify="space-evenly" h="80px" w="100%" borderTop="solid 1px gray" p="10px">
            <Text color="gray">@ Copyright 2020-2022</Text>
            <HStack spacing="14px">
                <Text color="white">Developed by</Text>
                <Image src="/twodev.png" borderRadius="full" w="50px" />
            </HStack>
        </HStack>
    </VStack>
}