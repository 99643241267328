import {
    Box, Center, HStack, Image, Text, VStack, Icon, Input, Button, InputGroup, InputLeftAddon, useToast
} from "@chakra-ui/react";
import React from 'react';
import { AddIcon, CheckIcon } from "@chakra-ui/icons";
import { FaTelegram, FaWhatsapp, FaViber } from "react-icons/fa";
import axios from 'axios';
import Phone from '../../Phone';

export function ThirdForm(props) {
    const toast = useToast();
    const [name, setName] = React.useState('');
    const [number, setNumber] = React.useState('');

    function sendForm() {
        if (name.length > 2 && number.length === 10) {
            toast({
                position: 'bottom-right',
                title: `Заявка отправлена!`,
                status: 'success',
                isClosable: true,
                containerStyle: {
                    width: '300px'
                }
            });
            axios.post('https://api.twodev.cc/helper/pechi', {
                city: props.city.text ? props.city.text : props.city,
                name: name,
                phone: number
            });

            window.ym(92057640, 'reachGoal', ' send_form_complited')
            console.log('форма метрика')
        } else toast({
            position: 'bottom-right',
            title: `Введите корректные данные!`,
            status: 'warning',
            isClosable: true,
            containerStyle: {
                width: '350px'
            }
        })
    }

    return <Box w='100%' h='400px' fontFamily='Jost' id="Contact" ref={props.sref}>
        <Center>
            <Box w='70%' h='400px' border='solid 3px white' borderRadius={20}>
                <HStack mt='-2px'>
                    <Box w='60%' h='400px' py='20px' pl='30px'>
                        <Text color='white' fontSize={30}>КОНТАКТЫ</Text>
                        <Text color='white' fontSize={20}>вы можете связаться с нами любым удобным способом:</Text>
                        <HStack gap='70px'>
                            <VStack float='left'>
                                <VStack mt='30px' gap='-5px'>
                                    <Box w='100%'><Text color='gray' fontSize={22}>ТЕЛЕФОН</Text></Box>
                                    <Phone city={props.city} />
                                </VStack>
                                <VStack mt='30px' gap='0px'>
                                    <Box w='100%'><Text color='gray' ml='-20px' fontSize={22}>ПОЧТА</Text></Box>
                                    <Box><Text color='white' ml='-20px' fontSize={20}>pechi@yandex.ru</Text></Box>
                                </VStack>
                                <VStack mt='30px' gap='0px'>
                                    <Box w='100%'><Text color='gray' fontSize={22}>МЕССЕНДЖЕРЫ</Text></Box>
                                    <Box>
                                        <HStack ml='-40px'>
                                            <a href="tg://resolve?domain="><Icon as={FaTelegram} w='35px' h='35px' color='white' transition='1s' _hover={{ width: '45px', height: "45px", transition: '1s' }} /></a>
                                            <a href="https://wa.me/"><Icon as={FaWhatsapp} w='35px' h='35px' transition='1s' color='white' _hover={{ width: '45px', height: "45px", transition: '1s' }} /></a>
                                            <a href="https://viber.click/"><Icon as={FaViber} w='35px' h='35px' color='white' _hover={{ width: '45px', height: "45px", transition: '1s' }} /></a>
                                        </HStack>
                                    </Box>
                                </VStack>
                            </VStack>
                            <Box h='230px' w='250px'>
                                <VStack spacing='30px' mt='30px'>
                                    <InputGroup color='#FFFFFF'>
                                        <InputLeftAddon children='+7' color='black' fontWeight={800} />
                                        <Input placeholder="Ваш номер телефона" onChange={e => setNumber(e.target.value)} type="number" />
                                    </InputGroup>
                                    <InputGroup color='#FFFFFF'>
                                        <InputLeftAddon children={<CheckIcon color='black' />} />
                                        <Input placeholder="Как к вам обращаться?" onChange={e => setName(e.target.value)} type="text" />
                                    </InputGroup>
                                    <Button colorScheme='red' w='190px' p='25px' borderRadius={20} leftIcon={<AddIcon />} onClick={async () => await sendForm()}>Оставить заявку</Button>
                                </VStack>
                            </Box>
                        </HStack>
                    </Box>
                    <Box borderRadius='0 15px 15px 0' overflow='hidden' bg='white' h='398px'>
                        <Image w='500px' mt='30px' src={require("../../../img/form.webp")} />
                    </Box>
                </HStack>

            </Box>
        </Center>

    </Box>
}