import React from 'react';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Text,
    Link,
    useDisclosure,
    VStack,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    HStack,
    Center
} from "@chakra-ui/react";
import getLink from "../../../../getLink";
import { HamburgerIcon } from "@chakra-ui/icons";
import semantics from "../../../../semantics.json";

const sFirst = Object.keys(semantics);
const sSecond = Object.values(semantics);

export function NavMenu() {
    const { isOpen: isOpenSide, onOpen: onOpenSide, onClose: onCloseSide } = useDisclosure();
    const btnRefSide = React.useRef();
    const [expandedFirst, setExpandedFirst] = React.useState([]);
    const [expandedSecond, setExpandedSecond] = React.useState([]);
    const [expandedThird, setExpandedThird] = React.useState([]);

    return <>
        <Button
            ref={btnRefSide}
            onClick={onOpenSide}
            bg='none'
            color="white"
            fontSize="40px"
            p="0px"
            leftIcon={<HamburgerIcon />}
            w="100px"
            _hover={{ background: "none", color: "gray" }}
            _active={{ background: "none", color: "gray" }}
        />

        <Drawer
            isOpen={isOpenSide}
            placement='top'
            onClose={onCloseSide}
            finalFocusRef={btnRefSide}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader fontFamily='Jost' fontSize={30}>Меню</DrawerHeader>

                <DrawerBody>
                    <VStack spacing='50px'>
                        <HStack fontFamily='Jost' w='90%' pb={expandedFirst.length > 0 ? "100px" : "0px"} transition="0.3s">
                            {Object.keys(semantics).slice(0, -9).map((s, i) => {
                                return <Box w='100%'>
                                    <Accordion allowToggle>
                                        <AccordionItem h="100px" borderTop="none" borderBottom="none" borderRight={`${i === 4 ? "0" : "2"}px solid red`}>
                                            <AccordionButton onClick={() => setExpandedFirst(expandedFirst.includes(s) ? expandedFirst.filter(x => x !== s) : expandedFirst.concat([s]))} justifyContent="center" colorScheme='red' size='lg' h='100px' variant='outline' fontSize={20}>{sFirst[i].toUpperCase()}<AccordionIcon /></AccordionButton>
                                            <AccordionPanel>
                                                <VStack>
                                                    {Object.entries(sSecond[i]).map(h => <Link _hover={{ textDecoration: "none" }} w="100%" href={getLink(h[1].link)}><Button colorScheme="red" w="100%" p="20px" fontWeight="lighter">{h[0]}</Button></Link>)}
                                                </VStack>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Box>
                            })}
                        </HStack>
                        <HStack fontFamily='Jost' w='90%' mt={expandedFirst.length > 0 ? "100px" : "0px"} pb={expandedSecond.length > 0 ? "100px" : "0px"} transition="0.3s">
                            {Object.keys(semantics).slice(5, -4).map((s, i) => {
                                return <Box w='100%'>
                                    <Accordion allowToggle>
                                        <AccordionItem h="100px" borderTop="none" borderBottom="none" borderRight={`${i === 4 ? "0" : "2"}px solid red`}>
                                            <AccordionButton onClick={() => setExpandedSecond(expandedSecond.includes(s) ? expandedSecond.filter(x => x !== s) : expandedSecond.concat([s]))} justifyContent="center" colorScheme='red' h='100px' size='lg' variant='outline' fontSize={20}>{sFirst[i + 5].toUpperCase()}<AccordionIcon /></AccordionButton>
                                            <AccordionPanel>
                                                <VStack>
                                                    {Object.entries(sSecond[i + 5]).map(h => <Link _hover={{ textDecoration: "none" }} w="100%" href={getLink(h[1].link)}><Button colorScheme="red" w="100%" p="20px" fontWeight="lighter">{h[0]}</Button></Link>)}
                                                </VStack>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Box>
                            })}
                        </HStack>
                        <HStack fontFamily='Jost' w='90%' pb={expandedThird.length > 0 ? "150px" : "0px"} transition="0.3s">
                            {Object.keys(semantics).slice(10).map((s, i) => {
                                return <Box w='100%'>
                                    <Accordion allowToggle>
                                        <AccordionItem h="100px" borderTop="none" borderBottom="none" borderRight={`${i === 3 ? "0" : "2"}px solid red`}>
                                            <AccordionButton onClick={() => setExpandedThird(expandedThird.includes(s) ? expandedThird.filter(x => x !== s) : expandedThird.concat([s]))} justifyContent="center" colorScheme='red' h='100px' size='lg' variant='outline' fontSize={20}>{sFirst[i + 10].toUpperCase()}<AccordionIcon /></AccordionButton>
                                            <AccordionPanel>
                                                <VStack>
                                                    {Object.entries(sSecond[i + 10]).map(h => <Link _hover={{ textDecoration: "none" }} w="100%" href={getLink(h[1].link)}><Button colorScheme="red" w="100%" p="20px" fontWeight="lighter">{h[0]}</Button></Link>)}
                                                </VStack>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </Box>
                            })}
                        </HStack>
                    </VStack>
                    <Center>
                        <HStack w='50%' mt='50px' ml='70px'>
                            <HStack fontFamily='Jost' w='90%' pb={expandedThird.length > 0 ? "150px" : "0px"} transition="0.3s">
                                {Object.keys(semantics).slice(12).map((s, i) => {
                                    return <Box w='100%'>
                                        <Accordion allowToggle>
                                            <AccordionItem h="100px" borderTop="none" borderBottom="none" borderRight={`${i === 1 ? "0" : "2"}px solid red`}>
                                                <Link _hover={{ textDecoration: "none" }} w="100%" href={getLink(`/${i === 0 ? 'price' : 'works'}`)}><AccordionButton justifyContent="center" colorScheme='red' h='100px' size='lg' variant='outline' fontSize={20}>{i === 0 ? 'НАШИ РАСЦЕНКИ' : 'ВЫПОЛНЕННЫЕ РАБОТЫ'}</AccordionButton></Link>
                                            </AccordionItem>
                                        </Accordion>
                                    </Box>
                                })}
                            </HStack>
                        </HStack>
                    </Center>
                </DrawerBody>

                <DrawerFooter>
                    <Text fontFamily='Jost'>© 2022 Кладу печь - официальная компания</Text>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    </>
}
