import React from "react";
import { Box, Text, HStack, VStack, Button, Switch, Checkbox, CheckboxGroup, Image, InputLeftAddon, InputGroup, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, useToast } from "@chakra-ui/react";
import { typeButton, typeButton_active, typeButton_hover } from "./style";
import { price } from "../../../price";
import { CloseIcon, AddIcon } from "@chakra-ui/icons";
import axios from 'axios';

export function Calculator(props) {
    const [type, setType] = React.useState("bannaya1");
    const [equip, setEquip] = React.useState("red");
    const [moreThan50, setMoreThan50] = React.useState(false);
    const [leftAdd, setLeftAdd] = React.useState([]);
    const [rightAdd, setRightAdd] = React.useState([]);
    const [kaminSize, setKaminSize] = React.useState(1);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [name, setName] = React.useState('');
    const [number, setNumber] = React.useState('');

    function sendForm() {
        
        if (name.length > 2 && number.length === 10) {
            toast({
                position: 'bottom-right',
                title: `Заявка отправлена!`,
                status: 'success',
                isClosable: true,
                containerStyle: {
                    width: '300px'
                }
            });
            axios.post('https://api.twodev.cc/helper/pechi', {
                city: props.city.text ? props.city.text : props.city,
                name: name,
                phone: number
            });

            window.ym(92057640, 'reachGoal', ' send_form_complited')
            console.log('форма метрика')
        } else toast({
            position: 'bottom-right',
            title: `Введите корректные данные!`,
            status: 'warning',
            isClosable: true,
            containerStyle: {
                width: '350px'
            }
        })
    }

    return <Box id="Calc" w='80%' ref={props.sref}>
        <Text color="white" fontSize={30} ml='70px'>ВЫ МОЖЕТЕ САМИ СОБРАТЬ<br /> СЕБЕ ПЕЧЬ</Text>

        <VStack pt="4vh" spacing="6vh">
            <HStack p={30} spacing='4vw' onClick={() => { setEquip("red"); setMoreThan50(false); setLeftAdd([]); setRightAdd([]); setKaminSize(1); }}>
                <Button
                    style={typeButton} _hover={typeButton_hover} _active={typeButton_active}
                    borderBottom={`2px solid ${type === "bannaya1" ? "red" : "rgba(0,0,0,0)"}`}
                    color={type === "bannaya1" ? "white" : "gray"}
                    onClick={() => setType("bannaya1")}
                >БАННАЯ ПЕЧЬ</Button>

                <Button
                    style={typeButton} _hover={typeButton_hover} _active={typeButton_active}
                    borderBottom={`2px solid ${type === "kaminopech2" ? "red" : "rgba(0,0,0,0)"}`}
                    color={type === "kaminopech2" ? "white" : "gray"}
                    onClick={() => setType("kaminopech2")}
                >КАМИНО-ПЕЧЬ</Button>

                <Button
                    style={typeButton} _hover={typeButton_hover} _active={typeButton_active}
                    borderBottom={`2px solid ${type === "kamini3" ? "red" : "rgba(0,0,0,0)"}`}
                    color={type === "kamini3" ? "white" : "gray"}
                    onClick={() => setType("kamini3")}
                >КАМИНЫ</Button>

                <Button
                    style={typeButton} _hover={typeButton_hover} _active={typeButton_active}
                    borderBottom={`2px solid ${type === "pech4" ? "red" : "rgba(0,0,0,0)"}`}
                    color={type === "pech4" ? "white" : "gray"}
                    onClick={() => setType("pech4")}
                >ПЕЧЬ</Button>

                <Button
                    style={typeButton} _hover={typeButton_hover} _active={typeButton_active}
                    borderBottom={`2px solid ${type === "barbeque5" ? "red" : "rgba(0,0,0,0)"}`}
                    color={type === "barbeque5" ? "white" : "gray"}
                    onClick={() => setType("barbeque5")}
                >БАРБЕКЮ<br />КОМПЛЕКС</Button>
            </HStack>

            <HStack spacing="30px">
                <HStack w={700} h={300} spacing="-4px" ml="-100px" justify="center" align="end">
                    {type === "barbeque5" && leftAdd.includes('stolik') && <Image src={require(`../../../img/calculator/add/${equip}/stolik.webp`)} w="110px" h="100px" />}
                    {type === "barbeque5" && leftAdd.includes('duhovka') && <Image src={require(`../../../img/calculator/add/${equip}/duhovka.webp`)} w="150px" h="200px" />}
                    {type === "kamini3" ? <Image src={require(`../../../img/calculator/${type}/${equip}.webp`)} w="200px" h="200px" transform={`scale(${kaminSize})`} /> : <Image src={require(`../../../img/calculator/${type}/${equip}.webp`)} w={type === "kaminopech2" ? "250px" : "200px"} h={type === "barbeque5" ? "400px" : "300px"} />}
                    {type === "barbeque5" && rightAdd.includes('koptilnya') && <Image src={require(`../../../img/calculator/add/${equip}/koptilnya.webp`)} w="150px" h="200px" />}
                    {type === "barbeque5" && rightAdd.includes('rakovina') && <Image src={require(`../../../img/calculator/add/${equip}/rakovina.webp`)} w="100px" h="100px" />}
                </HStack>

                <VStack spacing="20px">
                    <VStack spacing="30px">
                        <Text color="white" fontWeight="bold" fontSize={25}>{type === "kamini3" ? "Размер" : "Облицовка"}</Text>

                        {type === "kamini3"
                            ? <HStack w="100%" justify="space-around">
                                <VStack w="60%">
                                    <VStack>
                                        <Button borderRadius="13px" w="200px" h='50px' _hover={{ border: "2px solid gray" }} _active={{ border: "2px solid red" }} border={`solid 1px ${kaminSize === 1 ? "red" : "white"}`} bg="gray.700" onClick={() => setKaminSize(1)}><Text color="white">Малый - 63см</Text></Button>
                                        <Button borderRadius="13px" w="200px" h='50px' _hover={{ border: "2px solid gray" }} _active={{ border: "2px solid red" }} border={`solid 1px ${kaminSize === 1.5 ? "red" : "white"}`} bg="gray.700" onClick={() => setKaminSize(1.5)}><Text color="white">Средний - 76см и 90см</Text></Button>
                                        <Button borderRadius="13px" w="200px" h='50px' _hover={{ border: "2px solid gray" }} _active={{ border: "2px solid red" }} border={`solid 1px ${kaminSize === 2 ? "red" : "white"}`} bg="gray.700" onClick={() => setKaminSize(2)}><Text color="white">Большой - 114см</Text></Button>
                                    </VStack>
                                </VStack>
                            </HStack>
                            : <HStack spacing='90px' w="100%" justify="space-between">
                                <VStack w="60%">
                                    <Text color='white' fontSize={18}>Обычный кирпич</Text>
                                    <HStack>
                                        <Button borderRadius="13px" w='65px' h='65px' _hover={{ border: "2px solid gray" }} _active={{ border: "2px solid red" }} border={`solid 2px ${equip === "red" ? "red" : "black"}`} bgImage={require("../../../img/calculator/thumbnails/default/red.webp")} backgroundSize='100%' onClick={() => setEquip("red")} />
                                        <Button borderRadius="13px" w='65px' h='65px' _hover={{ border: "2px solid gray" }} _active={{ border: "2px solid red" }} border={`solid 2px ${equip === "brown" ? "red" : "black"}`} bgImage={require("../../../img/calculator/thumbnails/default/brown.webp")} backgroundSize='100%' onClick={() => setEquip("brown")} />
                                        <Button borderRadius="13px" w='65px' h='65px' _hover={{ border: "2px solid gray" }} _active={{ border: "2px solid red" }} border={`solid 2px ${equip === "beige" ? "red" : "black"}`} bgImage={require("../../../img/calculator/thumbnails/default/beige.webp")} backgroundSize='100%' onClick={() => setEquip("beige")} />
                                    </HStack>
                                </VStack>

                                <VStack w="60%">
                                    <Text color='white' fontSize={18} textAlign="center">Премиум кирпич</Text>
                                    <VStack>
                                        <HStack>
                                            <Button borderRadius="13px" w='65px' h='65px' _hover={{ border: "2px solid gray" }} _active={{ border: "2px solid red" }} border={`solid 2px ${equip === "pred" ? "red" : "black"}`} bgImage={require("../../../img/calculator/thumbnails/premium/red.webp")} backgroundSize='100%' onClick={() => setEquip("pred")} />
                                            {type !== "barbeque5" && <Button borderRadius="13px" w='65px' h='65px' _hover={{ border: "2px solid gray" }} _active={{ border: "2px solid red" }} border={`solid 2px ${equip === "pbrown" ? "red" : "black"}`} bgImage={require("../../../img/calculator/thumbnails/premium/brown.webp")} backgroundSize='100%' onClick={() => setEquip("pbrown")} />}
                                            <Button borderRadius="13px" w='65px' h='65px' _hover={{ border: "2px solid gray" }} _active={{ border: "2px solid red" }} border={`solid 2px ${equip === "pbeige" ? "red" : "black"}`} bgImage={require("../../../img/calculator/thumbnails/premium/beige.webp")} backgroundSize='100%' onClick={() => setEquip("pbeige")} />
                                        </HStack>
                                    </VStack>
                                </VStack>
                            </HStack>}

                        {type === "bannaya1" && <HStack w="100%" justify="space-around">
                            <VStack w="60%">
                                <Text color='white' fontSize={18}>Премиум кирпич с худож. кладкой</Text>
                                <HStack>
                                    <Button borderRadius="13px" w='65px' h='65px' _hover={{ border: "2px solid gray" }} _active={{ border: "2px solid red" }} border={`solid 2px ${equip === "hred" ? "red" : "black"}`} bgImage={require("../../../img/calculator/thumbnails/premium/red.webp")} backgroundSize='100%' onClick={() => setEquip("hred")} />
                                    <Button borderRadius="13px" w='65px' h='65px' _hover={{ border: "2px solid gray" }} _active={{ border: "2px solid red" }} border={`solid 2px ${equip === "hbrown" ? "red" : "black"}`} bgImage={require("../../../img/calculator/thumbnails/premium/brown.webp")} backgroundSize='100%' onClick={() => setEquip("hbrown")} />
                                    <Button borderRadius="13px" w='65px' h='65px' _hover={{ border: "2px solid gray" }} _active={{ border: "2px solid red" }} border={`solid 2px ${equip === "hbeige" ? "red" : "black"}`} bgImage={require("../../../img/calculator/thumbnails/premium/beige.webp")} backgroundSize='100%' onClick={() => setEquip("hbeige")} />
                                </HStack>
                            </VStack>
                        </HStack>}
                    </VStack>

                    <HStack spacing="50px" display={type === "barbeque5" ? "flex" : "none"}>
                        <VStack w="60%" spacing="20px">
                            <Text fontWeight="bold" color="white" fontSize={25}>Пристройки</Text>

                            <VStack spacing="20%">
                                <CheckboxGroup colorScheme='whiteAlpha.0' onChange={setLeftAdd}>
                                    <Checkbox isInvalid w="100px" value='duhovka' transform="scale(1.5)" iconColor="red.500" icon={leftAdd.includes("duhovka") ? <CloseIcon /> : <></>} color="white">Духовка</Checkbox>
                                    <Checkbox isInvalid w="100px" value='stolik' transform="scale(1.5)" iconColor="red.500" icon={leftAdd.includes("stolik") ? <CloseIcon /> : <></>} color="white">Столик</Checkbox>
                                </CheckboxGroup>
                                <CheckboxGroup colorScheme='whiteAlpha.0' onChange={setRightAdd}>
                                    <Checkbox isInvalid w="100px" value='rakovina' transform="scale(1.5)" iconColor="red.500" icon={rightAdd.includes("rakovina") ? <CloseIcon /> : <></>} color="white">Раковина</Checkbox>
                                    <Checkbox isInvalid w="100px" value='koptilnya' transform="scale(1.5)" iconColor="red.500" icon={rightAdd.includes("koptilnya") ? <CloseIcon /> : <></>} color="white">Коптильня</Checkbox>
                                </CheckboxGroup>
                            </VStack>
                        </VStack>

                        <VStack w="60%">
                            <Text fontWeight="bold" color='white' fontSize={25}>Площадь парной</Text>
                            <HStack gap='10px'>
                                <Text color='white' w="100px" textAlign="right">до 50 м<sup>2</sup></Text>
                                <Switch size='lg' colorScheme='red' onChange={e => setMoreThan50(e.target.checked)} />
                                <Text color='white' w="100px" textAlign="left">свыше 50 м<sup>2</sup></Text>
                            </HStack>
                        </VStack>
                    </HStack>

                    <VStack spacing="10px">
                        <Text color='white' fontSize={25} mt='20px'>ИТОГОВАЯ ЦЕНА</Text>
                        <Text color='white' fontSize={50} mt='-20px'>{parseInt((price[type] + (type === "kamini3" ? (price[kaminSize]) : (price[equip]) * (moreThan50 ? 1.5 : 1)))).toLocaleString()} ₽</Text>
                        <Button
                            bg='#D0493E'
                            color='white'
                            borderRadius='44px'
                            w='220px'
                            h='55px'
                            transition='1s'
                            _hover={{
                                bg: '#bd4934', cursor: 'pointer', filter: 'drop-shadow(0px 10px 20px #E15757)', transition: '0.5s', mt: '1px',
                                textDecoration: 'none'
                            }}
                            onClick={onOpen}
                        >
                            ЗАКАЗАТЬ СЕЙЧАС</Button>
                    </VStack>
                </VStack>
            </HStack>
        </VStack>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontFamily={22}>Оформление заявки</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Text mb="20px">После оформления заявки наш менеджер свяжется с Вами!</Text>
                    <VStack w='100%' spacing='10px' >
                        <Input onChange={e => setName(e.target.value)} type="text" placeholder="Как к Вам обращаться?" h='60px' borderRadius={20} />
                        <InputGroup>
                            <InputLeftAddon children='+7' h='60px' borderRadius='20px 0 0 20px' />
                            <Input onChange={e => setNumber(e.target.value)} type="number" placeholder="Номер телефона" h='60px' borderRadius={20} />
                        </InputGroup>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="red" w="190px" p="25px" borderRadius={20} leftIcon={<AddIcon />} onClick={async () => await sendForm()}>Оставить заявку</Button>
                    <Button colorScheme="gray" w="190px" p="25px" borderRadius={20} ml="20px" onClick={onClose}>Закрыть</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Box>
}