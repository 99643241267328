import React from 'react';
import { SimpleGrid, GridItem, Text, Box, VStack, Button, Link, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, useToast, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons';
import "./style.css";
import getLink from "../../../getLink";
import axios from 'axios';

const infoButtonStyles = {
    backgroundColor: "#D0493E",
    color: "white",
    borderRadius: "44px",
    width: "200px",
    height: "50px",
    transition: "0.3s",
    display: "block"
};

const infoButtonStylesHover = {
    marginTop: "1px",
    filter: "drop-shadow(0px 10px 20px #E15757)",
    backgroundColor: "#bd4934",
    cursor: "pointer"
};

const blockHeadingStyles = {
    color: 'white',
    textAlign: 'center',
    fontSize: '30px'
}



export function Catalog(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [name, setName] = React.useState('');
    const [number, setNumber] = React.useState('');

    function sendForm() {
        if (name.length > 2 && number.length === 10) {
            toast({
                position: 'bottom',
                title: `Заявка отправлена!`,
                status: 'success',
                isClosable: true,
                containerStyle: {
                    width: '300px'
                }
            });
            axios.post('https://api.twodev.cc/helper/pechi', {
                city: props.city.text ? props.city.text : props.city,
                name: name,
                phone: number
            });

            window.ym(92057640, 'reachGoal', ' send_form_complited')
            console.log('форма метрика');
        } else toast({
            position: 'bottom',
            title: `Введите корректные данные!`,
            status: 'warning',
            isClosable: true,
            containerStyle: {
                width: '350px'
            }
        })
    }

    return <VStack fontFamily="Jost" w='90%' mt='80px' gap='40px' id="Catalog" ref={props.sref}>
        <Box w="100%" p='0'><Text color="white" fontSize="30px" float='left'>МЫ СОЗДАЁМ ЛЮБЫЕ ВИДЫ ПЕЧНЫХ КОНСТРУКЦИЙ</Text></Box>

        <SimpleGrid
            columns={1}
            gap="20px"
            w='100%'
        >
            <GridItem bgImage={require("../../../img/oven1.webp")} borderRadius="28px" className="gridItemLeft">
                <VStack className="gridItemContent" gap='10px' py='70px'>
                    <Text style={blockHeadingStyles}>БАННЫЕ ПЕЧИ</Text>
                    <Link href={getLink("/bannaya-pech-iz-kirpicha")} _hover={{ textDecoration: "none" }}><Button style={infoButtonStyles} _hover={infoButtonStylesHover}>Подробнее</Button></Link>
                </VStack>
            </GridItem>

            <GridItem bgImage={require("../../../img/oven2.webp")} borderRadius="28px" className="gridItemLeft">
                <VStack className="gridItemContent" gap='10px' py='70px'>
                    <Text style={blockHeadingStyles}>КОМПЛЕКС БАРБЕКЮ</Text>
                    <Link href={getLink("/barbequ-iz-kirpicha")} _hover={{ textDecoration: "none" }}><Button style={infoButtonStyles} _hover={infoButtonStylesHover}>Подробнее</Button></Link>
                </VStack>
            </GridItem>

            <GridItem bgImage={require("../../../img/oven3.webp")} borderRadius="28px" className="gridItemLeft">
                <VStack className="gridItemContent" gap='10px' py='70px'>
                    <Text style={blockHeadingStyles}>КАМИНО-ПЕЧИ</Text>
                    <Link href={getLink("/kaminopech-iz-kirpicha-dlya-doma")} _hover={{ textDecoration: "none" }}><Button style={infoButtonStyles} _hover={infoButtonStylesHover}>Подробнее</Button></Link>
                </VStack>
            </GridItem>

            <GridItem bg="white" pos='relative' zIndex={10} borderRadius="44px" className="gridItemLeft">
                <Button bg='white' w='100%' h='100%' borderRadius="44px" fontSize={21} py='30px'
                    onClick={onOpen}>Проконсультироваться</Button>
            </GridItem>

            <GridItem bgImage={require("../../../img/oven4.webp")} borderRadius="28px" className="gridItemLeft">
                <VStack className="gridItemContent" gap='10px' py='70px'>
                    <Text style={blockHeadingStyles}>КАМИНЫ</Text>
                    <Link href={getLink("/kamin-iz-kirpicha")} _hover={{ textDecoration: "none" }}><Button style={infoButtonStyles} _hover={infoButtonStylesHover}>Подробнее</Button></Link>
                </VStack>
            </GridItem>

            <GridItem bgImage={require("../../../img/oven5.webp")} borderRadius="28px" className="gridItemLeft">
                <VStack className="gridItemContent" gap='10px' py='70px'>
                    <Text style={blockHeadingStyles}>ПЕЧИ</Text>
                    <Link href={getLink("/russkaya-pech")} _hover={{ textDecoration: "none" }}><Button style={infoButtonStyles} _hover={infoButtonStylesHover}>Подробнее</Button></Link>
                </VStack>
            </GridItem>
        </SimpleGrid>
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontFamily={22}>Оформление заявки</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>                    
                    <Text mb="20px">После оформления заявки наш менеджер свяжется с Вами!</Text>
                    <VStack w='100%' spacing='10px' >
                        <Input onChange={e => setName(e.target.value)} type="text" placeholder="Как к Вам обращаться?" h='60px' borderRadius={20} />
                        <InputGroup>
                            <InputLeftAddon children='+7' h='60px' borderRadius='20px 0 0 20px' />
                            <Input onChange={e => setNumber(e.target.value)} type="number" placeholder="Номер телефона" max h='60px' borderRadius={20} />
                        </InputGroup>
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="red" w="190px" fontSize='14px' p="25px" borderRadius={20} leftIcon={<AddIcon />} onClick={async () => await sendForm()}>Оставить заявку</Button>
                    <Button colorScheme="gray" w="190px" fontSize='14px' p="25px" borderRadius={20} ml="20px" onClick={onClose}>Закрыть</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>


    </VStack>
}