import React, { useRef } from 'react';
import { motion } from "framer-motion";
import { VStack, HStack, Text, Icon, Box } from "@chakra-ui/react";
import { BsFileLock } from "react-icons/bs";
import { SiSpeedtest } from "react-icons/si";
import { AiOutlineFire } from "react-icons/ai";
import { Catalog, ParallaxText, Calculator, Interesting, Form, SecondForm, CarouselBlock, Advantages, ThirdForm, Bricks, Questions } from "../../components/Laptop";
import 'intersection-observer';
// import { useIsVisible } from 'react-is-visible';

export function Home(props) {
    const catalogRef = useRef();
    const calcRef = useRef();
    const intRef = useRef();
    const contactRef = useRef();
    const sfRef = useRef();
    const advRef = useRef();
    const carRef = useRef();
    const typeRef = useRef();

    // const catalogIsVisible = useIsVisible(catalogRef);
    // const calcIsVisible = useIsVisible(calcRef);
    // const intIsVisible = useIsVisible(intRef);
    // const contactIsVisible = useIsVisible(contactRef);
    // const sfIsVisible = useIsVisible(sfRef);
    // const advIsVisible = useIsVisible(advRef);
    // const carIsVisible = useIsVisible(carRef);
    // const typeIsVisible = useIsVisible(typeRef);

    return <>
        {/* <Nav toDisplay={catalogIsVisible ? "Catalog" : calcIsVisible ? "Calc" : (intIsVisible || sfIsVisible || advIsVisible || typeIsVisible) ? "Int" : (contactIsVisible || carIsVisible) ? "Contact" : "Catalog"} /> */}

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 2.5, duration: 1.5 }}
        >
            <VStack w='50%' ml='5%' fontFamily='Jost' p='70px' spacing="30px">
                <Text fontSize={40} color='white' textAlign='left'>ВЫКЛАДКА И УСТАНОВКА КИРПИЧНЫХ ПЕЧЕЙ В {(props.city.text2 ? props.city.text2 : props.city).toUpperCase()}</Text>

                <motion.div
                    initial={{ x: -500, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 2.7, duration: 1 }}
                >
                    <Text color='white' fontSize={20} mt='10px'>
                        Компания "Кладу Печь" выложит для Вас кирпичную печь для дома, дачи, русскую печь, камин и многое другое!
                    </Text>
                </motion.div>

                <motion.div
                    initial={{ x: 500, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 3, duration: 1 }}
                >
                    <Box>
                        <HStack gap='50px' ml='-40px' >
                            <VStack>
                                <Icon as={BsFileLock} color='white' w='30px' h='30px' />
                                <Text fontSize={14} color='white'>БЕЗОПАСНОСТЬ</Text>
                            </VStack>
                            <VStack>
                                <Icon as={SiSpeedtest} color='white' w='30px' h='30px' />
                                <Text fontSize={14} color='white'>ПРОФЕССИОНАЛИЗМ</Text>
                            </VStack>
                            <VStack>
                                <Icon as={AiOutlineFire} color='white' w='30px' h='30px' />
                                <Text fontSize={14} color='white'>УЮТ</Text>
                            </VStack>
                        </HStack>
                    </Box>
                </motion.div>
            </VStack>
        </motion.div>

        <motion.div
            style={{ marginTop: "30vh" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.5, duration: 1.5 }}
        >
            <VStack
                spacing="18vh"
                bg="#141416"
                pb="15vh"
            >
                <Catalog sref={catalogRef} city={props.city} />
                <ParallaxText />
                <Form city={props.city} />
                <Calculator sref={calcRef} />
                <Interesting sref={intRef} city={props.city} />
                <SecondForm sref={sfRef} />
                <Advantages sref={advRef} />
                <CarouselBlock sref={carRef} />
                <Bricks sref={typeRef} />
                <ThirdForm sref={contactRef} city={props.city} />
                <Questions />
            </VStack>
        </motion.div>
    </>
}