import {
    Box, HStack, VStack, Image, Text
} from '@chakra-ui/react';
import { Fade } from 'react-reveal';

export function Bricks(props) {
    return <VStack spacing="100px" w="70%" fontFamily="Jost" ref={props.sref}>
        <Box w="100%" p="0">
            <Text color="white" fontSize="35px" w="600px" float="left">ТИПЫ И ВИДЫ КИРПИЧЕЙ, КОТОРЫЕ МЫ ИСПОЛЬЗУЕМ</Text>
        </Box>
        <HStack>
            <Fade left><Box bg="gray.500" borderRadius="full" filter="blur(40px)" w="250px" h="200px" pos="relative" zIndex={0}></Box></Fade>
            <Box>
                <HStack ml="-240px" pos="relative" zIndex={1} spacing="100px">
                    <Fade left><Image src={require('../../../img/bricks/bricks1.webp')} /></Fade>
                    <Text color="white" fontSize={24} textAlign="left" w="600px">Красный кирпич – это наиболее часто применяемый материал, из этого материала можно выкладывать стены, перегородки и даже печи. Для стен может использоваться обычный пустотелый, а для кладки кирпичной печи бани мы
                        используем термостойкий марки М75 или М150.</Text>
                </HStack>
            </Box>
        </HStack>
        <HStack>
            <Box>
                <HStack mr="-230px" pos="relative" zIndex={1} spacing="100px">
                    <Text color="white" fontSize={24} textAlign="left" w="600px">Шамотный кирпич – относится к огнеупорным материалам, изготавливается на основе шамотной глины. В бане применяется для кладки топки печей, для обустройства экранов в деревянных банях и банях с металлическими
                        печами.</Text>
                    <Fade right><Image w="200px" src={require('../../../img/bricks/bricks3.webp')} /></Fade>
                </HStack>
            </Box>
            <Fade right><Box bg="gray.500" borderRadius="full" filter="blur(40px)" w="250px" h="200px" pos="relative" zIndex={0}></Box></Fade>
        </HStack>
        <HStack>
            <Fade left><Box bg="gray.500" borderRadius="full" filter="blur(40px)" w="250px" h="200px" pos="relative" zIndex={0}></Box></Fade>
            <Box>
                <HStack ml="-230px" pos="relative" zIndex={1} spacing="100px">
                    <Fade left><Image src={require('../../../img/bricks/bricks5.webp')} /></Fade>
                    <Text color="white" fontSize={24} textAlign="left" w="600px">Соляные кирпичи для бани – материал, появившийся относительно недавно, представляет собой соляные блоки, самыми лучшими считаются блоки Пакистанского производства из соли добывающейся в гималайских горах.</Text>
                </HStack>
            </Box>
        </HStack>
        <HStack>
            <Box>
                <HStack mr="-230px" pos="relative" zIndex={1} spacing="100px">
                    <Text color="white" fontSize={24} textAlign="left" w="600px">Газобетон и пенобетон – являются продуктом новых технологий. Представляют собой блоки достаточно большого размера, материал пористый, поэтому имеет малый вес.</Text>
                    <Fade right><Image w="200px" src={require('../../../img/bricks/bricks2.webp')} /></Fade>
                </HStack>
            </Box>
            <Fade right><Box bg="gray.500" borderRadius="full" filter="blur(40px)" w="250px" h="200px" pos="relative" zIndex={0}></Box></Fade>
        </HStack>
        <HStack>
            <Fade left><Box bg="gray.500" borderRadius="full" filter="blur(40px)" w="250px" h="200px" pos="relative" zIndex={0}></Box></Fade>
            <Box>
                <HStack ml="-250px" pos="relative" zIndex={1} spacing="100px">
                    <Fade left><Image src={require('../../../img/bricks/bricks4.webp')} /></Fade>
                    <Text color="white" fontSize={24} textAlign="left" w="600px">Силикатный кирпич для обустройства бани не подходит, так как он впитывает влагу и может использоваться исключительно внешней для облицовки.</Text>
                </HStack>
            </Box>
        </HStack>
    </VStack>
}