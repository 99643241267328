import "./style.css";
import { useRef } from "react";
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    useMotionValue,
    useVelocity,
    useAnimationFrame
} from "framer-motion";
import { wrap } from "@motionone/utils";
import { VStack } from "@chakra-ui/react";

function Parallax({ baseVelocity = 100 }) {
    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, { damping: 50, stiffness: 400 });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], { clamp: false });
    const x = useTransform(baseX, v => `${wrap(-20, -45, v)}%`);
    const directionFactor = useRef(1);

    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * baseVelocity * (delta / 300);

        if (velocityFactor.get() < 0) directionFactor.current = -1;
        else if (velocityFactor.get() > 0) directionFactor.current = 1;

        moveBy += directionFactor.current * moveBy * velocityFactor.get();
        baseX.set(baseX.get() + moveBy);
    });

    return <motion.div className="scroller" style={{ x }}>
        <span>&#160;ПЕЧЕЙ БАННЫЕ КОМПЛЕКСЫ ПЕЧИ ВЫКЛАДКА</span>
        <span>&#160;ПЕЧЕЙ БАННЫЕ КОМПЛЕКСЫ ПЕЧИ ВЫКЛАДКА</span>
        <span>&#160;ПЕЧЕЙ БАННЫЕ КОМПЛЕКСЫ ПЕЧИ ВЫКЛАДКА</span>
        <span>&#160;ПЕЧЕЙ БАННЫЕ КОМПЛЕКСЫ ПЕЧИ ВЫКЛАДКА</span>
        <span>&#160;ПЕЧЕЙ БАННЫЕ КОМПЛЕКСЫ ПЕЧИ ВЫКЛАДКА</span>
        <span>&#160;ПЕЧЕЙ БАННЫЕ КОМПЛЕКСЫ ПЕЧИ ВЫКЛАДКА</span>
        <span>&#160;ПЕЧЕЙ БАННЫЕ КОМПЛЕКСЫ ПЕЧИ ВЫКЛАДКА</span>
        <span>&#160;ПЕЧЕЙ БАННЫЕ КОМПЛЕКСЫ ПЕЧИ ВЫКЛАДКА</span>
        <span>&#160;ПЕЧЕЙ БАННЫЕ КОМПЛЕКСЫ ПЕЧИ ВЫКЛАДКА</span>
    </motion.div>
}

export function ParallaxText() {
    return <VStack overflow="hidden" w="100%">
        <Parallax baseVelocity={-2} />
        <Parallax baseVelocity={2} />
    </VStack>
}
