import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import LaptopApp from './LaptopApp';
import MobileApp from './MobileApp';
import './index.css';
import { Helmet } from 'react-helmet';
import cities from './cities.json';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import semantics from './semantics.json';
import NoMatch from './components/Laptop/NoMatch';

const oblasti = Object.entries(cities).filter(city => city);
const goroda = Object.values(cities).filter(city => city.cities).map(city => Object.entries(city.cities));
const routes = ['/price', '/works'];

const fr = [{
    path: '/',
    element: <>
        <Helmet>
            <link rel="icon" href="/favicon.ico" />
            <title>Кирпичная печь для дома и дачи "под ключ"</title>
            <meta name="description" content="Проектирование и кладка кирпичных печей для частных домов и дач 'под ключ'! Строительство печей из кирпича любой сложности и конструкций по индивидуальным заказам! Опытные печники. Гарантия качества и долговечности!" />
            <meta name="keywords" content="печи, укладка, москва" />
        </Helmet>
        {window.innerWidth > 1000 ? <LaptopApp page="main" city={{ text: 'Москва', text2: 'Москве' }} /> : <MobileApp page="main" city={{ text: 'Москва', text2: 'Москве' }} />}
    </>,
    errorElement: <NoMatch />
}];

routes.map(r => fr.push({
    path: r,
    element: <>
        <Helmet>
            <link rel="icon" href="/favicon.ico" />
            <title>Кирпичная печь для дома и дачи "под ключ"</title>
            <meta name="description" content="Проектирование и кладка кирпичных печей для частных домов и дач 'под ключ'! Строительство печей из кирпича любой сложности и конструкций по индивидуальным заказам! Опытные печники. Гарантия качества и долговечности!" />
            <meta name="keywords" content="печи, укладка, москва" />
        </Helmet>
        {window.innerWidth > 1000 ? <LaptopApp page={r.slice(1)} city={{ text: 'Москва', text2: 'Москве' }} /> : <MobileApp page={r.slice(1)} city={{ text: 'Москва', text2: 'Москве' }} />}
    </>
}));
oblasti.map(city => fr.push({
    path: `/${city[0]}`,
    element: <>
        <Helmet>
            <link rel="icon" href="/favicon.ico" />
            <title>Кирпичная печь для дома и дачи "под ключ" в {typeof city[1] === 'string' ? city[1] : city[1].text2}</title>
            <meta name="description" content={`Проектирование и кладка кирпичных печей для частных домов и дач 'под ключ'! Строительство печей из кирпича любой сложности и конструкций по индивидуальным заказам! Опытные печники. Гарантия качества и долговечности! В ${typeof city[1] === 'string' ? city[1] : city[1].text2}`} />
            <meta name="keywords" content={`печи, укладка, ${typeof city[1] === 'string' ? city[1] : city[1].text}`} />
        </Helmet>
        {window.innerWidth > 1000 ? <LaptopApp page="main" city={city[1]} /> : <MobileApp page="main" city={city[1]} />}
    </>
}));
goroda.map(c => c.map(city => fr.push({
    path: `/${city[0]}`,
    element: <>
        <Helmet>
            <link rel="icon" href="/favicon.ico" />
            <title>Кирпичная печь для дома и дачи "под ключ" в {typeof city[1] === 'string' ? city[1] : city[1].text2}</title>
            <meta name="description" content={'Проектирование и кладка кирпичных печей для частных домов и дач \'под ключ\'! Строительство печей из кирпича любой сложности и конструкций по индивидуальным заказам! Опытные печники. Гарантия качества и долговечности! В ' + typeof city[1] === 'string' ? city[1] : city[1].text2} />
            <meta name="keywords" content={'печи, кладка, ' + typeof city[1] === 'string' ? city[1] : city[1].text} />
        </Helmet>
        {window.innerWidth > 1000 ? <LaptopApp page="main" city={city[1]} /> : <MobileApp page="main" city={city[1]} />}
    </>
})));

routes.map(r => oblasti.map(city => fr.push({
    path: `/${city[0]}${r}`,
    element: <>
        <Helmet>
            <link rel="icon" href="/favicon.ico" />
            <title>Кирпичная печь для дома и дачи "под ключ" в {typeof city[1] === 'string' ? city[1] : city[1].text2}</title>
            <meta name="description" content={`Проектирование и кладка кирпичных печей для частных домов и дач 'под ключ'! Строительство печей из кирпича любой сложности и конструкций по индивидуальным заказам! Опытные печники. Гарантия качества и долговечности! В ${typeof city[1] === 'string' ? city[1] : city[1].text2}`} />
            <meta name="keywords" content={`печи, укладка, ${typeof city[1] === 'string' ? city[1] : city[1].text}`} />
        </Helmet>
        {window.innerWidth > 1000 ? <LaptopApp page={r.slice(1)} city={city[1]} /> : <MobileApp page={r.slice(1)} city={city[1]} />}
    </>
})));
routes.map(r => goroda.map(c => c.map(city => fr.push({
    path: `/${city[0]}${r}`,
    element: <>
        <Helmet>
            <link rel="icon" href="/favicon.ico" />
            <title>Кирпичная печь для дома и дачи "под ключ" в {typeof city[1] === 'string' ? city[1] : city[1].text2}</title>
            <meta name="description" content={'Проектирование и кладка кирпичных печей для частных домов и дач \'под ключ\'! Строительство печей из кирпича любой сложности и конструкций по индивидуальным заказам! Опытные печники. Гарантия качества и долговечности! В ' + typeof city[1] === 'string' ? city[1] : city[1].text2} />
            <meta name="keywords" content={'печи, укладка, ' + typeof city[1] === 'string' ? city[1] : city[1].text} />
        </Helmet>
        {window.innerWidth > 1000 ? <LaptopApp page={r.slice(1)} city={city[1]} /> : <MobileApp page={r.slice(1)} city={city[1]} />}
    </>
}))));

Object.values(semantics).map(l => Object.values(l).map(f => fr.push({
    path: f.link,
    element: <>
        <Helmet>
            <link rel="icon" href="/favicon.ico" />
            <title>{f.meta.title}</title>
            <meta name="description" content={f.meta.desc} />
            <meta name="keywords" content={`печи, укладка, москва`} />
        </Helmet>
        {window.innerWidth > 1000 ? <LaptopApp page="semantics" f={f} city={{ text: 'Москва', text2: 'Москве' }} /> : <MobileApp page="semantics" f={f} city={{ text: 'Москва', text2: 'Москве' }} />}
    </>
})));
Object.values(semantics).map(l => Object.values(l).map(f => oblasti.map(city => fr.push({
    path: `/${city[0]}${f.link}`,
    element: <>
        <Helmet>
            <link rel="icon" href="/favicon.ico" />
            <title>{f.meta.title}</title>
            <meta name="description" content={f.meta.desc} />
            <meta name="keywords" content={`печи, укладка, ${typeof city[1] === 'string' ? city[1] : city[1].text}`} />
        </Helmet>
        {window.innerWidth > 1000 ? <LaptopApp page="semantics" f={f} city={city[1]} /> : <MobileApp page="semantics" f={f} city={city[1]} />}
    </>
}))));
Object.values(semantics).map(l => Object.values(l).map(f => goroda.map(c => c.map(city => fr.push({
    path: `/${city[0]}${f.link}`,
    element: <>
        <Helmet>
            <link rel="icon" href="/favicon.ico" />
            <title>{f.meta.title + `${typeof city[1] === 'string' ? city[1] : city[1].text}`}</title>
            <meta name="description" content={f.meta.desc} />
            <meta name="keywords" content={`печи, укладка, ${typeof city[1] === 'string' ? city[1] : city[1].text}`} />
        </Helmet>
        {window.innerWidth > 1000 ? <LaptopApp page="semantics" f={f} city={city[1]} /> : <MobileApp page="semantics" f={f} city={city[1]} />}
    </>
})))));

ReactDOM.createRoot(document.getElementById('root')).render(<ChakraProvider><RouterProvider router={createBrowserRouter(fr)} /></ChakraProvider>);

// какая-то встроенная в реакт функция проверки производительности сайта
/* import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    getCLS(console.log);
    getFID(console.log);
    getFCP(console.log);
    getLCP(console.log);
    getTTFB(console.log);
}); */