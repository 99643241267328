import { Box } from "@chakra-ui/react";
import sample from './img/Back.mp4';
import { motion } from "framer-motion";
import React, { useEffect } from 'react';
import { Footer, Header } from "./components/Laptop";
import { Home, PricePage, SemanticsTest, WorksPage } from "./pages/Laptop";

export default function LaptopApp(props) {
    useEffect(() => {
        let oldValue = 0;
        let newValue = 0;

        window.addEventListener('scroll', () => {
            newValue = window.pageYOffset;

            if (newValue > window.innerHeight - 200 && oldValue - newValue < 0) document.getElementById('topHeader').style.marginTop = "-50%";
            else if (oldValue - newValue > 20) document.getElementById('topHeader').style.marginTop = "0";

            if (newValue < window.innerHeight / 3 && oldValue - newValue > 0) document.getElementById('sideNav').style.opacity = '0';
            else if (newValue > window.innerHeight / 3) document.getElementById('sideNav').style.opacity = '1';

            oldValue = newValue;
        });
    }, []);

    return <>
        {
            props.page === "main"
                ? <>
                    <Header city={props.city} type={'animate'} />
                    <Box position='fixed'
                         top='0'
                         zIndex='-1000'
                         width='100%'
                         filter='brightness(30%)'
                    >
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 1.5, duration: 1.5 }}
                        >
                            <video autoPlay loop muted style={{ width: "100%" }}>
                                <source src={sample} type='video/mp4' />
                            </video>
                        </motion.div>
                    </Box>
                </>
                : <Header city={props.city} type={'not'} />
        }

        <Box mt="24vh">
            {
                props.page === "main"
                    ? <Home city={props.city} />
                    : props.page === "price"
                    ? <PricePage city={props.city} />
                    : props.page === "works"
                        ? <WorksPage city={props.city} />
                        : <SemanticsTest f={props.f} city={props.city} />
            }
        </Box>

        <Footer />
    </>
}