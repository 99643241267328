import React from 'react';
import { motion } from "framer-motion";
import {
    VStack, Text, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer, Center, Image
} from '@chakra-ui/react';
import { ChevronRightIcon, ChevronDownIcon, ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import Pulse from 'react-reveal/Pulse';
import getLink from "../../getLink";
import { CarouselBlock, ThirdForm, Footer } from '../../components/Mobile';

export function SemanticsTest(props) {
    const { heading, text, subtitle, headingCharacteristicks,characteristics, img, tablecost } = props.f;

    return <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
    >
        <VStack w='100%' h='100%' mt='-70px' fontFamily='Jost' color='white' bg='#141416' spacing="50px" pb='100px'>
            <Box w='90%'>
                <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='white' />} mt='30px'>
                    <BreadcrumbItem>
                        <BreadcrumbLink href={getLink("/")} color='white' fontSize={19} transition="0.5s" _hover={{ textDecoration: "none", color: "gray.400", transition: "0.5s" }}>Главная</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href='#' color='white' transition="0.5s" fontSize={19} _hover={{ textDecoration: "none", color: "gray.400", transition: "0.5s" }}>{heading}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <Text color="white" fontSize={30} textAlign='center' mt='30px' >{heading} в {typeof props.city === 'string' ? props.city : props.city.text2}</Text>
                <Text color="white" fontSize={19} textAlign='left' mt='40px'>{subtitle}</Text>
            </Box>
            <VStack w='100%' spacing='6%'>
                <Image src={require(`../../img/pagesFurnace/${img}`)} h='500px' />
                <Box w='93%'>
                    <Pulse cascade>
                        <Box borderRadius='50px 15px 15px 50px' bg='#D0493E' p='20px'>
                            <Center><Text color='white' fontSize={22} w='97%'>{text}</Text></Center>
                        </Box>
                    </Pulse>
                </Box>
            </VStack>

            <VStack spacing='40px' maxW='93%' overflow='hidden' >

                <Text fontSize={25} textAlign='left'>Что мы используем при изготовление печи:</Text>

                <TableContainer w='100%'>
                    <Center><HStack mb='20px' spacing='-7px' ><ArrowBackIcon w='20px' h='20px' /><ArrowForwardIcon w='20px' h='20px' /></HStack></Center>
                    <Table sime='sm' color='white' fontSize={16}>
                        <Thead>
                            <Tr>
                                <Th>МАТЕРИАЛ</Th>
                                <Th>КОЛИЧЕСТВО(шт/кг)</Th>
                                <Th isNumeric>СТОИМОСТЬ (₽)</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>ШАМОТНЫЙ КИРПИЧ</Td>
                                <Td>20 - 30</Td>
                                <Td isNumeric>5000 - 7000</Td>
                            </Tr>
                            <Tr>
                                <Td>ПОЛНОТЕЛЫЙ КРАСНЫЙ КИРПИЧ</Td>
                                <Td>250 - 800</Td>
                                <Td isNumeric>30000 - 50000</Td>
                            </Tr>
                            <Tr>
                                <Td>КЛАДОЧНЫЙ РАСТРОВ</Td>
                                <Td>10-40 (ведёр)</Td>
                                <Td isNumeric>15000</Td>
                            </Tr>
                            <Tr>
                                <Td>ПЕЧНОЕ ЛИТЬЕ</Td>
                                <Td>индивидуально</Td>
                                <Td isNumeric>1000 - 20000</Td>
                            </Tr>
                            <Tr>
                                <Td>ТЕПЛОИЗОЛЯТОР</Td>
                                <Td>базальтовая вата</Td>
                                <Td isNumeric>1500 - 5000</Td>
                            </Tr>
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th fontSize={20}>ОБЩАЯ СТОИМОСТЬ</Th>
                                <Th></Th>
                                <Th isNumeric fontSize={23} color='white'>от {tablecost}₽</Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>

                <VStack w='90%' spacing='30px'>
                    <Box w='100%'><Text  color="white" fontSize="35px" float='left' pos='relative' zIndex={1}>{headingCharacteristicks} в {typeof props.city === 'string' ? props.city : props.city.text2}</Text></Box>
                    <Box>
                        <Text color='white' fontSize={19} pos='relative' zIndex={1}>{characteristics}</Text>
                        <Box bg='#D0493E' mt='-600px' w='50%' ml='25%' h='600px' borderRadius='110px' filter='blur(60px)' opacity='0.7' pos='relative' zIndex={0} />
                    </Box>
                </VStack>

                <Box>
                    <VStack mt='100px'>
                        <VStack spacing='30px'>
                            <VStack w='95%' spacing={5}>
                                <img src={require("../../img/svg/1.svg").default} style={{ width: "150px", height: "150px" }} alt='' display='block' />
                                <Text fontSize={22}>ПРОЕКТИРОВАНИЕ</Text>
                                <Box w='100%' borderBottom='solid 1px white' opacity='0.7'></Box>
                                <Text fontSize={18} textAlign='left' color='gray.400'>Наша работа с Вами начинается с проектного решения, дающие четкое понимание, что делать</Text>
                            </VStack>
                            <ChevronDownIcon w='40px' h='40px' />
                            <VStack w='95%' spacing={5}>
                                <img src={require("../../img/svg/3.svg").default} style={{ width: "150px", height: "150px" }} alt='' display='block' />
                                <Text fontSize={22}>ВЫКЛАДКА</Text>
                                <Box w='100%' borderBottom='solid 1px white' opacity='0.7'></Box>
                                <Text fontSize={18} textAlign='left' color='gray.400'>В своей работе используем только проверенные бренды и современные, качественные технологии</Text>
                            </VStack>
                            <ChevronDownIcon w='40px' h='40px' />
                            <VStack w='95%' spacing={5}>
                                <img src={require("../../img/svg/2.svg").default} style={{ width: "150px", height: "150px" }} alt='' display='block' />
                                <Text fontSize={22}>ОТДЕЛКА</Text>
                                <Box w='100%' borderBottom='solid 1px white' opacity='0.7'></Box>
                                <Text fontSize={18} textAlign='left' color='gray.400'>Дизайнерские решения для отделки печи, установка дополнительного оборудования</Text>
                            </VStack>
                            <ChevronDownIcon w='40px' h='40px' />
                            <VStack w='95%' spacing={5}>
                                <img src={require("../../img/svg/4.svg").default} style={{ width: "130px", height: "150px" }} alt='' display='block' />
                                <Text fontSize={22}>СДАЧА</Text>
                                <Box w='100%' borderBottom='solid 1px white' opacity='0.7'></Box>
                                <Text fontSize={18} textAlign='left' color='gray.400'>Полноценный анализ выполненной работы перед сдачей печи, полная проверка, гарантия качества</Text>
                            </VStack>
                        </VStack>
                    </VStack>
                </Box>
                <Box>
                    <Center>
                        <VStack w='90%' spacing='30px' mt='70px' >
                            <Box w='100%'><Text color="white" fontSize="35px" float='left' pos='relative' zIndex={1}>КАК ПОЯВИЛИСЬ ПЕЧИ?</Text></Box>
                            <Box>
                                <Text color='white' fontSize={19} w='97%' pos='relative' zIndex={1}>Вопреки расхожему мнению, кирпичные печи впервые появились не в России. Их завезли сюда уже во времена правления Петра I, который лично привез из Голландии несколько речей и пригласил опытных мастеров для
                                    передачи опыта строительства подобных сооружений.<br /><br /></Text>
                                <Center><Text color='white' fontSize={19} w='100%' pos='relative' zIndex={1}>
                                    Петр I издал указ, которым запретил растопку бани по-черному. С тех пор и пошла традиция использования кирпичных печей. Новинка быстро обрела популярность, поскольку оказалась сравнительно безопасной и достаточно простой, удобной в эксплуатации. С тех пор кирпичные печи
                                    стали базовым способом нагрева бани, успешно конкурирующим с легкими металлическими конструкциями.
                                </Text></Center>
                                <Box bg='gray.500' mt='-600px' w='50%' ml='30%' h='600px' borderRadius='110px' filter='blur(40px)' opacity='0.7' pos='relative' zIndex={0} />
                            </Box>
                        </VStack>
                    </Center>
                </Box>

                <CarouselBlock />

                <VStack w='93%' spacing='30px'>
                    <Box w='100%'><Text color="white" fontSize="25px" float='left' pos='relative' zIndex={1}>НА ЧТО СТОИТ ОБРАТИТЬ ВНИМАНИЕ ПРИ ОФОРМЛЕНИЕ ПЕЧИ</Text></Box>
                    <Box>
                        <Text color='white' fontSize={19} w='100%' pos='relative' zIndex={1}>Тщательно обсудите с менеджером полностью стоимость работы, отдельно строительство дымохода и фундамент, этапы кладочных работ, удобные сроки, стоимость кладочных работ, стоимость материала, составьте сметы
                            правильно.<br /><br /></Text>
                        <Center><Text color='white' fontSize={19} w='100%' pos='relative' zIndex={1}>
                            Современные печи это системы высокой сложности, выполняющие много функций и требований. Самостоятельно разобраться в нюансах кладки очень сложно, поэтому мы обязательно предлагаем помощь в выборе, вы получаете весь комплекс работ и выгодную стоимость строительства.
                            Доступна гарантия компании. Оставьте заявку на нашем сайте, закажите выездную консультацию печника и получите полную информацию и расчет стоимости работ под ключ. Мы работаем быстро и к каждому проекту подходим индивидуально.<br /><br />
                        </Text></Center>
                        <Center><Text color='white' fontSize={19} w='100%' pos='relative' zIndex={1}>
                            Мы обязательно просушиваем печь на 50-70% и сдаём в дальнейшую эксплуатацию.
                            Таким образом мы снижаем риск образования микротрещин что приводит к разрушению структуры печи.<br /><br />
                        </Text></Center>
                        <Box bg='#D0493E' mt='-700px' w='30%' ml='40%' h='700px' borderRadius='110px' filter='blur(60px)' opacity='0.7' pos='relative' zIndex={0} />
                    </Box>
                </VStack>
                <ThirdForm />
            </VStack>
            <Footer />
        </VStack >
    </motion.div >
}