import React from 'react';
import { motion } from 'framer-motion';
import { VStack, HStack, Text, Icon } from '@chakra-ui/react';
import { BsFileLock } from 'react-icons/bs';
import { SiSpeedtest } from 'react-icons/si';
import { AiOutlineFire } from 'react-icons/ai';
import { Catalog, ParallaxText, Calculator, Interesting, Form, SecondForm, CarouselBlock, Advantages, ThirdForm, Bricks, Footer, Questions } from '../../components/Mobile';

export function Home(props) {
    return <>
        <VStack w="100%" ml="0%" fontFamily="Jost" p="0px" spacing="50px">
            <Text fontSize={28} color="white" textAlign="justify" w="86%" lineHeight="50px">УКЛАДКА И УСТАНОВКА КИРПИЧНЫХ ПЕЧЕЙ В {(props.city.text2 ? props.city.text2 : props.city).toUpperCase()}</Text>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <HStack gap="10px" ml="-7px" mt="50px">
                    <VStack>
                        <Icon as={BsFileLock} color="white" w="30px" h="30px" />
                        <Text fontSize={14} color="white">БЕЗОПАСНОСТЬ</Text>
                    </VStack>
                    <VStack>
                        <Icon as={SiSpeedtest} color="white" w="30px" h="30px" />
                        <Text fontSize={14} color="white">ПРОФЕССИОНАЛИЗМ</Text>
                    </VStack>
                    <VStack>
                        <Icon as={AiOutlineFire} color="white" w="30px" h="30px" />
                        <Text fontSize={14} color="white">УЮТ</Text>
                    </VStack>
                </HStack>
            </motion.div>
        </VStack>

        <motion.div
            style={{ marginTop: '13vh' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.5, duration: 1.5 }}
        >
            <VStack
                spacing="18vh"
                bg="#141416"
                pb="15vh"
            >
                <Catalog />
                <ParallaxText />
                <Form />
                <Calculator />
                <Interesting city={props.city} />
                <Questions />
                <SecondForm />
                <Advantages />
                <CarouselBlock />
                <Bricks />
                <ThirdForm />
                <Footer />
            </VStack>
        </motion.div>
    </>
}