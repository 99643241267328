export const price = {
    // type
    "bannaya1": 10000,
    "kaminopech2": 8000,
    "kamini3": 5000,
    "pech4": 7000,
    "barbeque5": 20000,
    // equip
    "red": 0,
    "brown": 5000,
    "beige": 10000,
    // premium equip
    "pred": 5000,
    "pbrown": 10000,
    "pbeige": 15000,
    // hudozh. kladka
    "hred": 15000,
    "hbrown": 20000,
    "hbeige": 25000,
    // kaminSize
    1: 10000,
    1.5: 20000,
    2: 30000
}