import React from 'react';
import {
    VStack, Text, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Image, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, InputGroup, Input, useToast, InputLeftAddon
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import getLink from "../../getLink";
import { ChevronRightIcon, AddIcon } from "@chakra-ui/icons";
import { Bricks, ThirdForm } from '../../components/Laptop';
import axios from 'axios';

export function WorksPage(props) {

    const infoButtonStyles = {
        backgroundColor: "#D0493E",
        color: "white",
        borderRadius: "44px",
        width: "200px",
        height: "50px",
        transition: "0.3s"
    };

    const infoButtonStylesHover = {
        marginTop: "1px",
        filter: "drop-shadow(0px 10px 20px #E15757)",
        backgroundColor: "#bd4934",
        cursor: "pointer"
    };

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [name, setName] = React.useState('');
    const [number, setNumber] = React.useState('');

    function sendForm() {
        if (name.length > 2 && number.length === 10) {
            toast({
                position: 'bottom-right',
                title: `Заявка отправлена!`,
                status: 'success',
                isClosable: true,
                containerStyle: {
                    width: '300px'
                }
            });
            axios.post('https://api.twodev.cc/helper/pechi', {
                city: props.city.text ? props.city.text : props.city,
                name: name,
                phone: number
            });

            window.ym(92057640, 'reachGoal', ' send_form_complited')
            console.log('форма метрика')
        } else toast({
            position: 'bottom-right',
            title: `Введите корректные данные!`,
            status: 'warning',
            isClosable: true,
            containerStyle: {
                width: '350px'
            }
        })
    }

    return <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
    >
        <VStack w='100%' h='100%' mt='-70px' fontFamily='Jost' color='white' bg='#141416' spacing="90px" pb='100px'>
            <Box w='73%' maxW='1200px'>
                <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='white' />} mt='30px'>
                    <BreadcrumbItem>
                        <BreadcrumbLink href={getLink("/")} color='white' fontSize={25} transition="0.5s" _hover={{ textDecoration: "none", color: "gray.400", transition: "0.5s" }}>Главная</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href='#' color='white' transition="0.5s" fontSize={25} _hover={{ textDecoration: "none", color: "gray.400", transition: "0.5s" }}>Выполненные работы</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <Text color="white" fontSize={50} textAlign='center' mt='30px'>ВЫПОЛНЕННЫЕ РАБОТЫ</Text>
                <Text color="white" fontSize={24} textAlign='left' mt='20px'>За время нашей работы мы выполнили не один десяток заказов и оставили всех клиентов довольными. Здесь вы можете посмотреть фото работы и краткий отчет об определенном заказе. <br /><span style={{ fontSize: '19px' }} >Для заказа печи из списке выполненных работ или любой другой вы можете оставить заявку внизу страницы</span></Text>
                <VStack mt='50px' spacing='70px' >
                    <HStack spacing='50px' borderRadius='10px' overflow='hidden' border='solid 0.1px gray' pr='10px' >
                        <Image src={require("../../img/imgCarousel/1.webp")} h='350px' />
                        <VStack spacing='50px' >
                            <Text fontSize='40px' textAlign='center'>Камин из красного шамотного кирпича</Text>
                            <Text fontSize={20} color='gray.500' >Выполняли заказ в городе Волоколамск. Угловной камин был закан заказан в частный дом, со вторым этажем, что принесло небольшие трудности, но наш печник выполнил заказ качественно и в срок.</Text>
                            <HStack spacing='50px' >
                                <VStack>
                                    <Text fontSize={22}>СТОИМОСТЬ</Text>
                                    <Text fontSize={35}>90000 руб.</Text>
                                </VStack>
                                <VStack>
                                    <Text fontSize={22}>СРОК ВЫПОЛНЕНИЯ</Text>
                                    <Text fontSize={35}>7 рабочих дней</Text>
                                </VStack>
                                <Button style={infoButtonStyles} _hover={infoButtonStylesHover} ml='30px' onClick={onOpen} >ОФОРМИТЬ</Button>
                            </HStack>
                        </VStack>
                    </HStack>
                    <HStack spacing='40px' borderRadius='10px' overflow='hidden' border='solid 0.1px gray' pl='20px' >
                        <VStack spacing='50px' >
                            <Text fontSize='40px' textAlign='center'>Печь-камин с местом хранения</Text>
                            <Text fontSize={20} color='gray.500' >Выполняли заказ в Можайске. Заказчик хотел печь-камин с местом для хранения дров, так чтобы это было удобно и выглядело стильно. </Text>
                            <HStack spacing='50px' >
                                <VStack>
                                    <Text fontSize={22}>СТОИМОСТЬ</Text>
                                    <Text fontSize={35}>95000 руб.</Text>
                                </VStack>
                                <VStack>
                                    <Text fontSize={22}>СРОК ВЫПОЛНЕНИЯ</Text>
                                    <Text fontSize={35}>6 рабочих дней</Text>
                                </VStack>
                                <Button style={infoButtonStyles} _hover={infoButtonStylesHover} ml='30px' onClick={onOpen} >ОФОРМИТЬ</Button>
                            </HStack>
                        </VStack>
                        <Image src={require("../../img/imgCarousel/2.webp")} h='350px' />
                    </HStack>
                    <HStack spacing='50px' borderRadius='10px' overflow='hidden' border='solid 0.1px gray' pr='10px' >
                        <Image src={require("../../img/imgCarousel/8.webp")} h='350px' />
                        <VStack spacing='50px' >
                            <Text fontSize='40px' textAlign='center'>Барбекю комплекс с варочной зоной</Text>
                            <Text fontSize={20} color='gray.500' >Выполняли заказ в Новороссийске. Клиент хотел перед летом создать на даче душевный и удобный комплекс Барбекю, с чем мы ему и помогли!</Text>
                            <HStack spacing='40px' >
                                <VStack>
                                    <Text fontSize={22}>СТОИМОСТЬ</Text>
                                    <Text fontSize={35}>140000 руб.</Text>
                                </VStack>
                                <VStack>
                                    <Text fontSize={22}>СРОК ВЫПОЛНЕНИЯ</Text>
                                    <Text fontSize={35}>10 рабочих дней</Text>
                                </VStack>
                                <Button style={infoButtonStyles} _hover={infoButtonStylesHover} ml='30px' onClick={onOpen} >ОФОРМИТЬ</Button>
                            </HStack>
                        </VStack>
                    </HStack>
                    <HStack spacing='40px' borderRadius='10px' overflow='hidden' border='solid 0.1px gray' pl='20px' >
                        <VStack spacing='20px' >
                            <Text fontSize='40px' textAlign='center'>Отопительно-варочная печь из шамотного кирпича</Text>
                            <Text fontSize={20} color='gray.500' >Выполняли заказ в городе Киров. Заказчику очень была необходима компактность, функиональсть и красота. Наш специалист смог объеденить все 3 желания и воплатить в реальность.</Text>
                            <HStack spacing='50px' >
                                <VStack>
                                    <Text fontSize={22}>СТОИМОСТЬ</Text>
                                    <Text fontSize={35}>75000 руб.</Text>
                                </VStack>
                                <VStack>
                                    <Text fontSize={22}>СРОК ВЫПОЛНЕНИЯ</Text>
                                    <Text fontSize={35}>9 рабочих дней</Text>
                                </VStack>
                                <Button style={infoButtonStyles} _hover={infoButtonStylesHover} ml='30px' onClick={onOpen} >ОФОРМИТЬ</Button>
                            </HStack>
                        </VStack>
                        <Image src={require("../../img/imgCarousel/4.webp")} h='350px' />
                    </HStack>
                    <HStack spacing='50px' borderRadius='10px' overflow='hidden' border='solid 0.1px gray' pr='10px' >
                        <Image src={require("../../img/imgCarousel/11.webp")} h='350px' />
                        <VStack spacing='50px' >
                            <Text fontSize='40px' textAlign='center'>Банная печь с банным комплексом</Text>
                            <Text fontSize={20} color='gray.500' >Выполняли заказ в городе Каменка. Банная печь, в дополнение с зоной парилки, отлично получилась у нашего специалиста.</Text>
                            <HStack spacing='30px' >
                                <VStack>
                                    <Text fontSize={22}>СТОИМОСТЬ</Text>
                                    <Text fontSize={35}>250000 руб.</Text>
                                </VStack>
                                <VStack>
                                    <Text fontSize={22}>СРОК ВЫПОЛНЕНИЯ</Text>
                                    <Text fontSize={35}>17 рабочих дней</Text>
                                </VStack>
                                <Button style={infoButtonStyles} _hover={infoButtonStylesHover} ml='30px' onClick={onOpen} >ОФОРМИТЬ</Button>
                            </HStack>
                        </VStack>
                    </HStack>
                </VStack>
            </Box>
            <Bricks />
            <ThirdForm city={props.city} />
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontFamily={22}>Оформление заявки</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Text mb="20px">После оформления заявки наш менеджер свяжется с Вами!</Text>
                        <VStack w='100%' spacing='10px' >
                            <Input onChange={e => setName(e.target.value)} type="text" placeholder="Как к Вам обращаться?" h='60px' borderRadius={20} />
                            <InputGroup>
                                <InputLeftAddon children='+7' h='60px' borderRadius='20px 0 0 20px' />
                                <Input onChange={e => setNumber(e.target.value)} type="number" placeholder="Номер телефона" max h='60px' borderRadius={20} />
                            </InputGroup>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="red" w="190px" p="25px" borderRadius={20} leftIcon={<AddIcon />} onClick={async () => await sendForm()}>Оставить заявку</Button>
                        <Button colorScheme="gray" w="190px" p="25px" borderRadius={20} ml="20px" onClick={onClose}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </VStack>
    </motion.div>
}