import { VStack, Text, Link, Button } from "@chakra-ui/react";
import cities from '../cities.json';
import { useEffect, useState } from "react";

export default function Phone({ city }) {

    const [data, setData] = useState([]);

    useEffect(() => {
        Object.values(cities).map(x => x.text === 'Московская область' && upd(x.cities));
        console.log(city);
    }, []);

    function upd(x) {
        Object.values(x).map(x => setData(old => [...old, x]))
    };

    return <VStack>        
        <Link href={`tel:${data.includes(city) ? '+79257096009' : '+79257096009'}`} _hover={{ textDecoration: 'none' }} onClick={() => { window.ym(92057640, 'reachGoal', ' call_mobile_phone') }} isExternal ><Text color='white' fontSize={20}>{data.includes(city) || city === 'Москва' || !city ? '+7 (925) 709-60-09' : '+7 (925) 00000000'}</Text></Link>
    </VStack>
}