export const typeButton = {
    background: "none",
    borderRadius: "0",
    fontSize: "26px",
    padding: "4px",
    height: "60px"
}

export const typeButton_active = {
    background: "none"
}

export const typeButton_hover = {
    background: "none",
    color: "lightgray"
}