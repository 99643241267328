import { Box } from '@chakra-ui/react';
import sample1 from './img/backMobile.mp4';
import { motion } from 'framer-motion';
import React from 'react';
import { Header } from './components/Mobile';
import { Home, SemanticsTest } from './pages/Mobile';
import PricePage from './pages/Mobile/PricePage';
import WorksPage from './pages/Mobile/WorksPage';

export default function MobileApp(props) {
    return <>
        <Header city={props.city} />

        <Box position="fixed"
             top="0"
             zIndex="-1000"
             width="100%"
             filter="brightness(30%)"
        >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
            >
                <video autoPlay loop muted style={{ width: '100%', height: '100%', transform: "scale(1.5)" }}>
                    <source src={sample1} type="video/mp4" />
                </video>
            </motion.div>
        </Box>

        <Box mt="24vh">
            {
                props.page === 'main'
                    ? <Home city={props.city} />
                    : props.page === 'price'
                    ? <PricePage city={props.city} />
                    : props.page === 'works'
                        ? <WorksPage city={props.city} />
                        : <SemanticsTest f={props.f} />
            }
        </Box>
    </>
}