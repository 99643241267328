import React from "react";
import { Box, HStack, Text, VStack, Image, Button, Input, useToast } from "@chakra-ui/react";
import { motion, AnimatePresence } from 'framer-motion';
import { price } from "../../../price";
import { PhoneIcon } from "@chakra-ui/icons";

export function Calculator() {
    const toast = useToast();
    const [page, setPage] = React.useState(1);
    const [type, setType] = React.useState('bannaya1');
    const [kaminSize, setKaminSize] = React.useState(null);
    const [equip, setEquip] = React.useState('');
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");

    function update(newType) {
        setPage(page + 1);

        if (page === 1) setType(newType);
        else if (page === 2) {
            if (type !== "kamini3") setEquip(newType);
            else setKaminSize(newType);
        }

        console.log(page, type, equip, kaminSize);
    }

    function handleSend() {
        console.log(type, equip, kaminSize, name, phone);

        if (name.length >= 2 && phone.length >= 10) {
            toast({
                position: 'bottom',
                title: `Заявка отправлена!`,
                status: 'success',
                isClosable: true,
                containerStyle: { width: '95%' }
            });

            /* await axios.post('https://kolodec-septiki.ru:49241/data', {
                city: props.city.text ? props.city.text : props.city,
                info: `Главная страница / септики; септик: ${props.septik}`,
                name: name,
                phone: phone
            }); */
            window.ym(92057640, 'reachGoal', ' send_form_complited')
            console.log('форма метрика')
        } else if (!toast.isActive("noToast")) toast({
            id: 'noToast',
            position: 'bottom',
            title: `Введите корректные данные!`,
            status: 'warning',
            isClosable: true,
            containerStyle: { width: '95%' }
        });
    }

    const Page1 = () => <VStack>
        <HStack border='solid 2px gray' borderRadius={12} onClick={() => update("bannaya1")}>
            <Box w='100px' h='100px' borderRadius={10} overflow='hidden'><Image w='100px' h='100px' src={require("../../../img/oven1.webp")} /></Box>
            <Text color="white" fontSize={30} w='210px' textAlign='center'>БАННАЯ ПЕЧЬ</Text>
        </HStack>
        <HStack border='solid 2px gray' borderRadius={12} onClick={() => update("barbeque5")}>
            <Box w='100px' h='100px' borderRadius={10} overflow='hidden'><Image w='100px' h='100px' src={require("../../../img/oven2.webp")} /></Box>
            <Text color="white" fontSize={30} w='210px' textAlign='center'>КОМПЛЕКС БАРБЕКЮ</Text>
        </HStack>
        <HStack border='solid 2px gray' borderRadius={12} onClick={() => update("kamini3")}>
            <Box w='100px' h='100px' borderRadius={10} overflow='hidden'><Image w='100px' h='100px' src={require("../../../img/oven3.webp")} /></Box>
            <Text color="white" fontSize={30} w='210px' textAlign='center'>КАМИН</Text>
        </HStack>
        <HStack border='solid 2px gray' borderRadius={12} onClick={() => update("kaminopech2")}>
            <Box w='100px' h='100px' borderRadius={10} overflow='hidden'><Image w='100px' h='100px' src={require("../../../img/oven4.webp")} /></Box>
            <Text color="white" fontSize={30} w='210px' textAlign='center'>КАМИНО-ПЕЧИ</Text>
        </HStack>
        <HStack border='solid 2px gray' borderRadius={12} onClick={() => update("pech4")}>
            <Box w='100px' h='100px' borderRadius={10} overflow='hidden'><Image w='100px' h='100px' src={require("../../../img/oven5.webp")} /></Box>
            <Text color="white" fontSize={30} w='210px' textAlign='center'>ПЕЧИ</Text>
        </HStack>
    </VStack>;

    const Page2 = () => <VStack spacing="30px">
        <Text color="white" fontWeight="bold" fontSize={25}>{type === "kamini3" ? "Размер" : "Облицовка"}</Text>

        {type === "kamini3"
            ? <VStack>
                <Button borderRadius="13px" w="200px" h='50px' border="solid 2px gray" bg="gray.700" onClick={() => update(1)}><Text color="white">Малый - 63см</Text></Button>
                <Button borderRadius="13px" w="200px" h='50px' border="solid 2px gray" bg="gray.700" onClick={() => update(1.5)}><Text color="white">Средний - 76см и 90см</Text></Button>
                <Button borderRadius="13px" w="200px" h='50px' border="solid 2px gray" bg="gray.700" onClick={() => update(2)}><Text color="white">Большой - 114см</Text></Button>
            </VStack>
            : <VStack>
                <VStack>
                    <Text color='white' fontSize={18}>Обычный кирпич</Text>
                    <HStack>
                        <Button borderRadius="13px" w='65px' h='65px' border="solid 2px gray" bgImage={require("../../../img/calculator/thumbnails/default/red.webp")} backgroundSize='100%' onClick={() => update("red")} />
                        <Button borderRadius="13px" w='65px' h='65px' border="solid 2px gray" bgImage={require("../../../img/calculator/thumbnails/default/brown.webp")} backgroundSize='100%' onClick={() => update("brown")} />
                        <Button borderRadius="13px" w='65px' h='65px' border="solid 2px gray" bgImage={require("../../../img/calculator/thumbnails/default/beige.webp")} backgroundSize='100%' onClick={() => update("beige")} />
                    </HStack>
                </VStack>

                <VStack>
                    <Text color='white' fontSize={18} textAlign="center">Премиум кирпич</Text>
                    <VStack>
                        <HStack>
                            <Button borderRadius="13px" w='65px' h='65px' border="solid 2px gray" bgImage={require("../../../img/calculator/thumbnails/premium/red.webp")} backgroundSize='100%' onClick={() => update("pred")} />
                            {type !== "barbeque5" && <Button borderRadius="13px" w='65px' h='65px' border="solid 2px gray" bgImage={require("../../../img/calculator/thumbnails/premium/brown.webp")} backgroundSize='100%' onClick={() => update("pbrown")} />}
                            <Button borderRadius="13px" w='65px' h='65px' border="solid 2px gray" bgImage={require("../../../img/calculator/thumbnails/premium/beige.webp")} backgroundSize='100%' onClick={() => update("pbeige")} />
                        </HStack>
                    </VStack>
                </VStack>
                {type === "bannaya1" && <VStack>
                    <Text color='white' fontSize={18}>Премиум кирпич с худож. кладкой</Text>
                    <HStack>
                        <Button borderRadius="13px" w='65px' h='65px' border="solid 2px gray" bgImage={require("../../../img/calculator/thumbnails/premium/red.webp")} backgroundSize='100%' onClick={() => update("hred")} />
                        <Button borderRadius="13px" w='65px' h='65px' border="solid 2px gray" bgImage={require("../../../img/calculator/thumbnails/premium/brown.webp")} backgroundSize='100%' onClick={() => update("hbrown")} />
                        <Button borderRadius="13px" w='65px' h='65px' border="solid 2px gray" bgImage={require("../../../img/calculator/thumbnails/premium/beige.webp")} backgroundSize='100%' onClick={() => update("hbeige")} />
                    </HStack>
                </VStack>}
            </VStack>}
    </VStack>;

    const Page3 = () => <VStack spacing="0px">
        <Text color='white' fontSize={25} mt='20px'>ИТОГОВАЯ ЦЕНА</Text>
        <Text color='white' fontSize={50} mt='0px'>{parseInt((price[type] + (type === "kamini3" ? (price[kaminSize]) : (price[equip])))).toLocaleString()} ₽</Text>

        <Input placeholder="Как к Вам обращаться?" onChange={setName} />
        <Box><Input placeholder="Ваш номер телефона" mt='10px' onChange={setPhone} /></Box>

        <Box>
            <Button
                bg='#D0493E'
                color='white'
                borderRadius='44px'
                w='220px'
                h='55px'
                mt='10px'
                _hover={{ bg: '#bd4934' }}
                leftIcon={<PhoneIcon />}
                onClick={handleSend}
            >
                ЗАКАЗАТЬ СЕЙЧАС</Button>
        </Box>
    </VStack>;

    return <VStack w='100%' fontFamily='Jost' spacing='50px'>
        <Box w="90%" p='0'>
            <Text color="white" fontSize="30px" float='left'>СОБЕРИТЕ СЕБЕ ПЕЧЬ САМИ</Text>
        </Box>

        <VStack bg='gray.800' borderRadius={20} w='95%' spacing='20px' py='30px'>
            <AnimatePresence mode='wait'>
                <motion.div
                    key={page}
                    initial={{ y: -10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 10, opacity: 0 }}
                    transition={{ duration: 0.4 }}
                >
                    <Text textAlign='center' fontSize={25} color="white">{page === 1 ? "Какую печь вы хотите?" : page === 2 ? "Какой цвет вы хотите?" : "Заказ подготовлен!"}</Text>
                </motion.div>
            </AnimatePresence>

            <HStack spacing='30px' pb="10px">
                <Box onClick={() => setPage(1)} w='50px' h='50px' borderRadius='full' fontSize='20px' border={`solid 2px ${page === 1 ? 'red' : 'gray'}`} py='8px' fontWeight={600} textAlign='center' color={page === 1 ? 'red' : 'gray'}>
                    1
                </Box>
                <Box onClick={() => setPage(2)} w='50px' h='50px' borderRadius='full' fontSize='20px' border={`solid 2px ${page === 2 ? 'red' : 'gray'}`} py='8px' fontWeight={600} textAlign='center' color={page === 2 ? 'red' : 'gray'}>
                    2
                </Box>
                <Box w='50px' h='50px' borderRadius='full' fontSize='20px' border={`solid 2px ${page === 3 ? 'red' : 'gray'}`} py='8px' fontWeight={600} textAlign='center' color={page === 3 ? 'red' : 'gray'}>
                    3
                </Box>
            </HStack>

            <AnimatePresence mode='wait'>
                <motion.div
                    key={page}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.4 }}
                >
                    {page === 1
                        ? <Page1 />
                        : page === 2
                            ? <Page2 />
                            : <Page3 />
                    }
                </motion.div>
            </AnimatePresence>
        </VStack>
    </VStack>
}