import React, { useState } from 'react';
import {
    VStack, Text, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Image, Button, Input, FormControl, ModalFooter, Icon, ModalContent, ModalBody, ModalHeader, Modal, ModalOverlay, useDisclosure, ModalCloseButton, HStack
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import getLink from "../../getLink";
import { ChevronRightIcon, AddIcon } from "@chakra-ui/icons";
import { Bricks, Footer, ThirdForm } from '../../components/Mobile';
import axios from 'axios';
import { AiFillCheckCircle } from "react-icons/ai";

export default function WorksPage(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [sent, setSent] = useState(false);

    function handleSend() {
        setSent(true);
        axios.post('https://api.twodev.cc/helper/pechi', {
            city: props.city.text ? props.city.text : props.city,
            info: ``,
            name: name,
            phone: phone
        });
        window.ym(92057640, 'reachGoal', ' send_form_complited')
        console.log('форма метрика')
    }

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');


    const infoButtonStyles = {
        backgroundColor: "#D0493E",
        color: "white",
        borderRadius: "44px",
        width: "200px",
        height: "50px",
        transition: "0.3s"
    };

    const infoButtonStylesHover = {
        marginTop: "1px",
        filter: "drop-shadow(0px 10px 20px #E15757)",
        backgroundColor: "#bd4934",
        cursor: "pointer"
    };    

    return <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
    >
        <VStack w='100%' h='100%' mt='-70px' fontFamily='Jost' color='white' bg='#141416' spacing="90px" pb='100px'>
            <Box w='90%'>
                <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='white' />} mt='30px'>
                    <BreadcrumbItem>
                        <BreadcrumbLink href={getLink("/")} color='white' fontSize={25} transition="0.5s" _hover={{ textDecoration: "none", color: "gray.400", transition: "0.5s" }}>Главная</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href='#' color='white' transition="0.5s" fontSize={20} _hover={{ textDecoration: "none", color: "gray.400", transition: "0.5s" }}>Выполненные работы</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <Text color="white" fontSize={35} textAlign='center' mt='30px'>ВЫПОЛНЕННЫЕ РАБОТЫ</Text>
                <Text color="white" fontSize={20} textAlign='left' mt='20px'>За время нашей работы мы выполнили не один десяток заказов и оставили всех клиентов довольными. Здесь вы можете посмотреть фото работы и краткий отчет об определенном заказе. <br /><span style={{ fontSize: '19px' }} >Для заказа печи из списке выполненных работ или любой другой вы можете оставить заявку внизу страницы.</span></Text>

                <VStack mt='50px' spacing='70px' >
                    <VStack spacing='30px' borderRadius='10px' overflow='hidden' border='solid 0.1px gray'>
                        <Image src={require("../../img/imgCarousel/1.webp")} h='400px' w='100%' />
                        <VStack spacing='30px' px='10px' pb='20px' >
                            <Text fontSize='30px' textAlign='center'>Камин из красного шамотного кирпича</Text>
                            <Text fontSize={20} color='gray.500' >Выполняли заказ в городе Волоколамск. Угловной камин был закан заказан в частный дом, со вторым этажем, что принесло небольшие трудности, но наш печник выполнил заказ качественно и в срок.</Text>
                            <VStack spacing='20px' >
                                <VStack>
                                    <Text fontSize={22}>СТОИМОСТЬ</Text>
                                    <Text fontSize={35}>90000 руб.</Text>
                                </VStack>
                                <VStack>
                                    <Text fontSize={22}>СРОК ВЫПОЛНЕНИЯ</Text>
                                    <Text fontSize={35}>7 рабочих дней</Text>
                                </VStack>
                                <Button style={infoButtonStyles} _hover={infoButtonStylesHover} ml='30px' onClick={onOpen} >ОФОРМИТЬ</Button>
                            </VStack>
                        </VStack>
                    </VStack>

                    <VStack spacing='30px' borderRadius='10px' overflow='hidden' border='solid 0.1px gray'>
                        <Image src={require("../../img/imgCarousel/2.webp")} h='400px' w='100%' />
                        <VStack spacing='30px' px='10px' pb='20px' >
                            <Text fontSize='30px' textAlign='center'>Печь-камин с местом хранения</Text>
                            <Text fontSize={20} color='gray.500' >Выполняли заказ в Можайске. Заказчик хотел печь-камин с местом для хранения дров, так чтобы это было удобно и выглядело стильно. </Text>
                            <VStack spacing='20px' >
                                <VStack>
                                    <Text fontSize={22}>СТОИМОСТЬ</Text>
                                    <Text fontSize={35}>95000 руб.</Text>
                                </VStack>
                                <VStack>
                                    <Text fontSize={22}>СРОК ВЫПОЛНЕНИЯ</Text>
                                    <Text fontSize={35}>6 рабочих дней</Text>
                                </VStack>
                                <Button style={infoButtonStyles} _hover={infoButtonStylesHover} ml='30px' onClick={onOpen} >ОФОРМИТЬ</Button>
                            </VStack>
                        </VStack>
                    </VStack>

                    <VStack spacing='30px' borderRadius='10px' overflow='hidden' border='solid 0.1px gray'>
                        <Image src={require("../../img/imgCarousel/8.webp")} h='400px' w='100%' />
                        <VStack spacing='30px' px='10px' pb='20px' >
                            <Text fontSize='30px' textAlign='center'>Барбекю комплекс с варочной зоной</Text>
                            <Text fontSize={20} color='gray.500' >Выполняли заказ в Новороссийске. Клиент хотел перед летом создать на даче душевный и удобный комплекс Барбекю, с чем мы ему и помогли!</Text>
                            <VStack spacing='20px' >
                                <VStack>
                                    <Text fontSize={22}>СТОИМОСТЬ</Text>
                                    <Text fontSize={35}>140000 руб.</Text>
                                </VStack>
                                <VStack>
                                    <Text fontSize={22}>СРОК ВЫПОЛНЕНИЯ</Text>
                                    <Text fontSize={35}>10 рабочих дней</Text>
                                </VStack>
                                <Button style={infoButtonStyles} _hover={infoButtonStylesHover} ml='30px' onClick={onOpen} >ОФОРМИТЬ</Button>
                            </VStack>
                        </VStack>
                    </VStack>


                    <VStack spacing='30px' borderRadius='10px' overflow='hidden' border='solid 0.1px gray'>
                        <Image src={require("../../img/imgCarousel/4.webp")} h='400px' w='100%' />
                        <VStack spacing='30px' px='10px' pb='20px' >
                            <Text fontSize='30px' textAlign='center'>Отопительно-варочная печь из шамотного кирпича</Text>
                            <Text fontSize={20} color='gray.500' >Выполняли заказ в городе Киров. Заказчику очень была необходима компактность, функиональсть и красота. Наш специалист смог объеденить все 3 желания и воплатить в реальность.</Text>
                            <VStack spacing='20px' >
                                <VStack>
                                    <Text fontSize={22}>СТОИМОСТЬ</Text>
                                    <Text fontSize={35}>75000 руб.</Text>
                                </VStack>
                                <VStack>
                                    <Text fontSize={22}>СРОК ВЫПОЛНЕНИЯ</Text>
                                    <Text fontSize={35}>9 рабочих дней</Text>
                                </VStack>
                                <Button style={infoButtonStyles} _hover={infoButtonStylesHover} ml='30px' onClick={onOpen} >ОФОРМИТЬ</Button>
                            </VStack>
                        </VStack>
                    </VStack>

                    <VStack spacing='30px' borderRadius='10px' overflow='hidden' border='solid 0.1px gray'>
                        <Image src={require("../../img/imgCarousel/11.webp")} h='400px' w='100%' />
                        <VStack spacing='30px' px='10px' pb='20px' >
                            <Text fontSize='30px' textAlign='center'>Банная печь с банным комплексом</Text>
                            <Text fontSize={20} color='gray.500' >Выполняли заказ в городе Каменка. Банная печь, в дополнение с зоной парилки, отлично получилась у нашего специалиста.</Text>
                            <VStack spacing='20px' >
                                <VStack>
                                    <Text fontSize={22}>СТОИМОСТЬ</Text>
                                    <Text fontSize={35}>250000 руб.</Text>
                                </VStack>
                                <VStack>
                                    <Text fontSize={22}>СРОК ВЫПОЛНЕНИЯ</Text>
                                    <Text fontSize={35}>17 рабочих дней</Text>
                                </VStack>
                                <Button style={infoButtonStyles} _hover={infoButtonStylesHover} ml='30px' onClick={onOpen} >ОФОРМИТЬ</Button>
                            </VStack>
                        </VStack>
                    </VStack>
                </VStack>
            </Box>
            <Bricks />
            <ThirdForm />
            <Footer />

            <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader fontFamily='Jost' >Оформление заявки</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6} fontFamily='Jost'>
                    <AnimatePresence mode="wait">
                        <motion.div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column"
                            }}
                            key={sent}
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.4 }}
                        >
                            {sent
                                ? <Icon as={AiFillCheckCircle} color="green" transform="scale(3)" />
                                : <>
                                    <Text mb="20px">После оформления заявки наш менеджер свяжется с Вами!</Text>
                                    <FormControl>
                                        <Input placeholder="Как к вам обращаться?" onChange={setName} />
                                    </FormControl>

                                    <FormControl mt={4}>
                                        <Input placeholder="Ваш номер телефона" onChange={setPhone} />
                                    </FormControl>
                                </>
                            }
                        </motion.div>
                    </AnimatePresence>
                </ModalBody>

                <ModalFooter>
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={sent}
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.4 }}
                        >
                            {sent
                                ? <></>
                                : <HStack>
                                        <Button colorScheme='red' w='180px' p='25px' borderRadius={20} leftIcon={<AddIcon />} onClick={handleSend}>Оставить заявку</Button>
                                        <Button colorScheme='gray' w='140px' p='25px' borderRadius={20} ml='10px' onClick={onClose}>Закрыть</Button>                                  
                                </HStack>
                            }
                        </motion.div>
                    </AnimatePresence>
                </ModalFooter>
            </ModalContent>
        </Modal>
        </VStack>
    </motion.div>
}