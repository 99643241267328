import React from 'react';
import { Box, Center, HStack, Image, Input, Text, Button, InputGroup, useToast, InputLeftAddon } from '@chakra-ui/react';
import axios from 'axios';

const infoButtonStyles = {
    backgroundColor: '#D0493E',
    color: 'white',
    borderRadius: '44px',
    width: '200px',
    height: '50px',
    transition: '0.3s'
};

const infoButtonStylesHover = {
    marginTop: '1px',
    filter: 'drop-shadow(0px 10px 20px #E15757)',
    backgroundColor: '#bd4934',
    cursor: 'pointer'
};

export function SecondForm(props) {
    const [name, setName] = React.useState('');
    const [number, setNumber] = React.useState('');
    const toast = useToast();

    function sendForm() {
        console.log(name,number)
        if (name.length > 2 && number.length === 10) {
            toast({
                position: 'bottom-right',
                title: `Заявка отправлена!`,
                status: 'success',
                isClosable: true,
                containerStyle: {
                    width: '300px'
                }
            });
            axios.post('https://api.twodev.cc/helper/pechi', {
                city: props.city.text ? props.city.text : props.city,
                name: name,
                phone: number
            });

            window.ym(92057640, 'reachGoal', ' send_form_complited')
            console.log('форма метрика')
        } else toast({
            position: 'bottom-right',
            title: `Введите корректные данные!`,
            status: 'warning',
            isClosable: true,
            containerStyle: {
                width: '350px'
            }
        })
    }

    return <Box w="100%" fontFamily="Jost" ref={props.sref}>
        <Center>
            <Box w="85%" bg="white" borderRadius={20} h="300px" px="50px">
                <HStack gap="40px">
                    <Box w="700px">
                        <Text fontSize={35} w="300px">
                            Не нашли то, что искали?
                        </Text>
                        <Text fontSize={22}>
                            Оставьте заявку на сайте и мы подготовим проект по запросу и построим качественно и в кратчайшие сроки!
                        </Text>
                        <HStack pt='10px'>
                            <Input type='text' onChange={e => setName(e.target.value)} placeholder="Как к Вам обращаться?" />
                            <InputGroup>
                                <InputLeftAddon children='+7' />
                                <Input type='number' onChange={e => setNumber(e.target.value)} w="200px" placeholder="Ваш номер телефона" />
                            </InputGroup>
                            <Box><Button style={infoButtonStyles} _hover={infoButtonStylesHover} ml="30px" onClick={async () => await sendForm()}>ОФОРМИТЬ</Button></Box>
                        </HStack>
                    </Box>
                    <Box>
                        <Image src={require('../../../img/secondform.webp')} w="400px" h="400px" mt="-80px" />
                    </Box>
                </HStack>
            </Box>
        </Center>
    </Box>
}