import React from 'react';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Text,
    Link,
    useDisclosure,
    VStack,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box
} from "@chakra-ui/react";
import getLink from "../../../../getLink";
import { HamburgerIcon } from "@chakra-ui/icons";
import semantics from "../../../../semantics.json";
import { CityPicker } from "./CityPicker";

const sFirst = Object.keys(semantics);
const sSecond = Object.values(semantics);

export function NavMenu(props) {
    const { isOpen: isOpenSide, onOpen: onOpenSide, onClose: onCloseSide } = useDisclosure();
    const btnRefSide = React.useRef();
    const [expanded, setExpanded] = React.useState(null);

    return <>
        <Button
            ref={btnRefSide}
            onClick={onOpenSide}
            bg='none'
            color="white"
            fontSize="40px"
            p="0px"
            leftIcon={<HamburgerIcon />}
            _hover={{ background: "none", color: "gray" }}
            _active={{ background: "none", color: "gray" }}
        />

        <Drawer
            isOpen={isOpenSide}
            placement='top'
            onClose={onCloseSide}
            finalFocusRef={btnRefSide}
        >
            <DrawerOverlay />
            <DrawerContent h="90%">
                <DrawerCloseButton />
                <DrawerHeader fontFamily='Jost' fontSize={30}>Меню</DrawerHeader>

                <DrawerBody>
                    <Accordion allowToggle>
                        <VStack fontFamily='Jost' w='100%' spacing="14px">
                            {Object.keys(semantics).map((s, i) => {
                                return <AccordionItem w="100%" h="50px" borderTop="none" borderBottom="none" borderRight='2px solid red' transition="0.3s" pb={expanded === s ? `${(Object.entries(sSecond[i]).length + 1) * 50}px` : "0px"}>
                                    <AccordionButton onClick={() => setExpanded(expanded === s ? null : s)} justifyContent="center" colorScheme='red' size='lg' h='50px' variant='outline' fontSize={20}>{sFirst[i].toUpperCase()}<AccordionIcon /></AccordionButton>
                                    <AccordionPanel transition="0.3s">
                                        <VStack>
                                            {Object.entries(sSecond[i]).map(h => <Link _hover={{ textDecoration: "none" }} w="100%" href={getLink(h[1].link)}><Button colorScheme="red" w="100%" p="20px" fontWeight="lighter">{h[0]}</Button></Link>)}
                                        </VStack>
                                    </AccordionPanel>
                                </AccordionItem>
                            })}
                        </VStack>
                    </Accordion>
                    <VStack w='100%' mt='14px'>
                            <VStack fontFamily='Jost' w='100%' transition="0.3s">
                                {Object.keys(semantics).slice(12).map((s, i) => {
                                    return <Box w='100%'>
                                        <Accordion allowToggle>
                                            <AccordionItem h="50px" borderTop="none" borderBottom="none" borderRight='2px solid red'>
                                                <Link _hover={{ textDecoration: "none" }} w="100%" href={getLink(`/${i === 0 ? 'price' : 'works'}`)}><AccordionButton justifyContent="center" colorScheme='red' h='50px' size='lg' variant='outline' fontSize={20}>{i === 0 ? 'НАШИ РАСЦЕНКИ' : 'ВЫПОЛНЕННЫЕ РАБОТЫ'}</AccordionButton></Link>
                                            </AccordionItem>
                                        </Accordion>
                                    </Box>
                                })}
                            </VStack>
                        </VStack>
                </DrawerBody>

                <DrawerFooter>
                    <VStack>
                        <CityPicker city={props.city} />
                        <Text fontFamily='Jost'>© 2022 Кладу печь - официальная компания</Text>
                    </VStack>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    </>
}
