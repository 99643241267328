import {
    Box, Center, HStack, Image, Text, VStack, Icon, Input, Button, InputGroup, InputLeftAddon, useToast
} from "@chakra-ui/react";
import React from 'react';
import { AddIcon, PhoneIcon, CheckIcon } from "@chakra-ui/icons";
import { FaTelegram, FaWhatsapp, FaViber } from "react-icons/fa";
import axios from "axios";
import Phone from '../../Phone';

export function ThirdForm(props) {

    const toast = useToast();
    // const { isOpen, onOpen, onClose } = useDisclosure();
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");

    const sendForm = () => {
        // console.log(props.septik, name, phone);

        if (name.length < 2 || phone.length < 10) toast({
            position: 'bottom-right',
            title: `Введите корректные данные!`,
            status: 'warning',
            isClosable: true,
            containerStyle: {
                width: '350px'
            }
        }); else {
            toast({
                position: 'bottom-right',
                title: `Заявка отправлена!`,
                status: 'success',
                isClosable: true,
                containerStyle: {
                    width: '300px'
                }
            });

            axios.post('https://api.twodev.cc/helper/pechi', {
                city: props.city.text ? props.city.text : props.city,
                info: ``,
                name: name,
                phone: phone
            });

            window.ym(92057640, 'reachGoal', ' send_form_complited')
            console.log('форма метрика')

            setName("");
            setPhone("");
        };
    };


    return <Box w='100%' fontFamily='Jost' id="Contact" ref={props.sref}>
        <Center>
            <Box w='93%' border='solid 3px white' borderRadius={20}>
                <VStack mt='-2px'>
                    <Box w='97%' py='20px' pl='30px'>
                        <Text color='white' fontSize={30}>КОНТАКТЫ</Text>
                        <Text color='white' fontSize={20}>вы можете связаться с нами любым удобным способом:</Text>

                        <VStack gap='10px'>
                            <VStack w='100%' spacing='-13px'>

                                <Box w='100%'><VStack mt='20px' spacing='-3px' float='left' >
                                    <Box w='100%'><Text color='gray' fontSize={22}>ТЕЛЕФОН</Text></Box>
                                    <Phone city={props.city} />
                                </VStack></Box>

                                <Box w='100%'><VStack mt='30px' spacing='-3px'>
                                    <Box w='100%'><Text color='gray' fontSize={22}>ПОЧТА</Text></Box>
                                    <Text color='white' w='100%' fontSize={20}>pechi@yandex.ru</Text>
                                </VStack></Box>

                                <Box w='100%'>
                                    <VStack mt='30px' gap='0px'>
                                        <Box w='100%'><Text color='gray' fontSize={22}>МЕССЕНДЖЕРЫ</Text></Box>
                                        <Box w='100%'>
                                            <HStack float='left' >
                                                <a href="tg://resolve?domain="><Icon as={FaTelegram} w='35px' h='35px' color='white' transition='1s' _hover={{ width: '45px', height: "45px", transition: '1s' }} /></a>
                                                <a href="https://wa.me/"><Icon as={FaWhatsapp} w='35px' h='35px' transition='1s' color='white' _hover={{ width: '45px', height: "45px", transition: '1s' }} /></a>
                                                <a href="https://viber.click/"><Icon as={FaViber} w='35px' h='35px' color='white' _hover={{ width: '45px', height: "45px", transition: '1s' }} /></a>
                                            </HStack>
                                        </Box>
                                    </VStack>
                                </Box>
                            </VStack>

                            <Box h='230px' w='100%'>
                                <VStack spacing='20px' mt='20px'>
                                    <InputGroup>
                                        <InputLeftAddon children={<PhoneIcon color='black' />} />
                                        <Input color='white' placeholder="Ваш номер телефона" w='70%' onChange={setPhone} />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputLeftAddon children={<CheckIcon color='black' />} />
                                        <Input color='white' placeholder="Как к вам обращаться?" w='70%'
                                            onChange={setName} />
                                    </InputGroup>
                                    <Button colorScheme='red' w='190px' p='25px' borderRadius={20} leftIcon={<AddIcon />}
                                        onClick={sendForm} >Оставить заявку</Button>
                                </VStack>
                            </Box>
                        </VStack>
                    </Box>

                    <Box borderRadius='0 0 15px 15px' overflow='hidden' bg='white'>
                        <Image w='500px' mt='30px' src={require("../../../img/form.webp")} />
                    </Box>
                </VStack>
            </Box>
        </Center >
    </Box >
}