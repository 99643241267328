import React from 'react';
import { Box, Button, Center, FormControl, Image, Input, Radio, RadioGroup, Text, VStack, FormLabel } from "@chakra-ui/react";
import { PhoneIcon, AddIcon } from "@chakra-ui/icons";
import axios from 'axios';

export function Form(props) {
    const [value, setValue] = React.useState('1');
    const [name, setName] = React.useState('');
    const [number, setNumber] = React.useState('');

    function sendForm() {
        if (name.length > 0 && number.length > 0) {
            axios.post('https://api.twodev.cc/helper/pechi', {
                city: props.city.text ? props.city.text : props.city,
                info: `Подарок: ${value}`,
                name: name,
                phone: number
            });
            window.ym(92057640, 'reachGoal', 'send_form_completed')
            console.log('форма метрика')
        } else {
            console.log('1')
        }
    }

    return <VStack gap='50px' w='97%' border='solid white 1px' borderRadius={22} pt='30px' >
        <Box mt='-10px' px='15px'>
            <Text color="white" fontSize={30} ml='10px' textAlign='left' >
                ВАША ПЕЧЬ СОБИРАЕТСЯ, И
            </Text>
            <Center>
                <Box w='100%' mt='30px' >
                    <Image src={require('../../../img/buildFurnace/1.webp')} w='800px' />
                    <Image src={require('../../../img/buildFurnace/2.webp')} w='800px' mt='-7px' />
                    <Image src={require('../../../img/buildFurnace/3.webp')} w='800px' mt='-7px' />
                    <Image src={require('../../../img/buildFurnace/4.webp')} w='800px' mt='-7px' />
                    <Image src={require('../../../img/buildFurnace/5.webp')} w='800px' mt='-7px' />
                    <Image src={require('../../../img/buildFurnace/6.webp')} w='800px' mt='-7px' />
                    <Image src={require('../../../img/buildFurnace/7.webp')} w='800px' mt='-7px' />
                    <Image src={require('../../../img/buildFurnace/8.webp')} w='800px' mt='-7px' />
                    <Image src={require('../../../img/buildFurnace/9.webp')} w='800px' mt='-7px' />
                    <Image src={require('../../../img/buildFurnace/10.webp')} w='800px' mt='-7px' />
                </Box>
            </Center>
        </Box>
        <Box w='100%' bg='white' borderRadius={20} px='20px' py='20px' >
            <Text fontSize={30} ml='0px' w='100%'>
                ВЫ УЖЕ МОЖЕТЕ ЗАКАЗАТЬ ЕЁ!
            </Text>
            <Center>
                <VStack mt='10px' w='80%' >
                    <FormControl >
                        <FormLabel onChange={setName} />
                        <Input type="text" placeholder="Как к Вам обращаться?"  />
                        <FormLabel onChange={setNumber} />
                        <Input type="number" placeholder="Ваш номер телефона" mt='10px' />
                        <Text mt='10px' >Выберите какой подарок вы хотите</Text>
                        <VStack mt='10px'>
                            <RadioGroup onChange={setValue} value={value} ml='5px' mb='8px' >
                                <Radio value='1'>Скидка 5%</Radio>
                                <Radio value='2'>2 дизайна проекта</Radio>
                                <Radio value='3'>Скидка 2000 руб</Radio>
                            </RadioGroup>
                        </VStack>
                    </FormControl>
                    <VStack gap='5px'>
                        <Button colorScheme='red' w='240px' p='25px' borderRadius={20} leftIcon={<AddIcon />}  onClick={sendForm}>Оставить заявку</Button>
                        <Button colorScheme='blackAlpha' w='240px' p='25px' borderRadius={20} leftIcon={<PhoneIcon />} >Позвонить</Button>
                    </VStack>
                </VStack>
            </Center>
        </Box>
    </VStack>
}