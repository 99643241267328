import React from 'react';
import { Box, Button, Center, HStack, Image, Input, InputGroup, Radio, RadioGroup, Text, useToast, VStack, InputLeftAddon, Link } from '@chakra-ui/react';
import { PhoneIcon, AddIcon } from '@chakra-ui/icons';
import { Fade } from 'react-reveal';
import axios from 'axios';

export function Form(props) {
    const toast = useToast();
    const [value, setValue] = React.useState('Два дизайна проекта');
    const [name, setName] = React.useState('');
    const [number, setNumber] = React.useState('');

    function sendForm() {
        if (name.length > 2 && number.length === 10) {
            toast({
                position: 'bottom-right',
                title: `Заявка отправлена!`,
                status: 'success',
                isClosable: true,
                containerStyle: {
                    width: '300px'
                }
            });
            axios.post('https://api.twodev.cc/helper/pechi', {
                city: props.city.text ? props.city.text : props.city,
                info: `Подарок: ${value}`,
                name: name,
                phone: number
            });

            window.ym(92057640, 'reachGoal', ' send_form_complited')
            console.log('форма метрика')
        } else toast({
            position: 'bottom-right',
            title: `Введите корректные данные!`,
            status: 'warning',
            isClosable: true,
            containerStyle: {
                width: '350px'
            }
        })
    }

    return <HStack gap="50px" border="solid white 1px" borderRadius={22} pl="15px">
        <Box mt="-10px">
            <Text color="white" fontSize={30} ml="10px" textAlign="left">
                ВАША ПЕЧЬ СОБИРАЕТСЯ, И
            </Text>
            <Center>
                <Box w="500px" mt="30px">
                    <Fade duration={1500}><Image src={require('../../../img/buildFurnace/1.webp')} w="800px" /></Fade>
                    <Fade duration={1600}><Image src={require('../../../img/buildFurnace/2.webp')} w="800px" mt="-7px" /></Fade>
                    <Fade duration={1700}><Image src={require('../../../img/buildFurnace/3.webp')} w="800px" mt="-7px" /></Fade>
                    <Fade duration={1800}><Image src={require('../../../img/buildFurnace/4.webp')} w="800px" mt="-7px" /></Fade>
                    <Fade duration={1900}><Image src={require('../../../img/buildFurnace/5.webp')} w="800px" mt="-7px" /></Fade>
                    <Fade duration={2000}><Image src={require('../../../img/buildFurnace/6.webp')} w="800px" mt="-7px" /></Fade>
                    <Fade duration={3000}><Image src={require('../../../img/buildFurnace/7.webp')} w="800px" mt="-7px" /></Fade>
                    <Fade duration={3000}><Image src={require('../../../img/buildFurnace/8.webp')} w="800px" mt="-7px" /></Fade>
                    <Fade duration={3000}><Image src={require('../../../img/buildFurnace/9.webp')} w="800px" mt="-7px" /></Fade>
                    <Fade duration={3000}><Image src={require('../../../img/buildFurnace/10.webp')} w="800px" mt="-7px" /></Fade>
                </Box>
            </Center>
        </Box>
        <Box w="500px" h="430px" bg="white" borderRadius={20} px="20px" py="20px">
            <Text fontSize={30} ml="0px" w="100%">
                ВЫ УЖЕ МОЖЕТЕ ЗАКАЗАТЬ ЕЁ!
            </Text>
            <Center>
                <VStack mt="20px" gap="10px" w="80%">
                    <VStack w='100%' spacing='10px' >
                        <Input onChange={e => setName(e.target.value)} type="text" placeholder="Как к Вам обращаться?" h='60px' borderRadius={20} />
                        <InputGroup>
                            <InputLeftAddon children='+7' h='60px' borderRadius='20px 0 0 20px' />
                            <Input onChange={e => setNumber(e.target.value)} type="number" placeholder="Номер телефона" max h='60px' borderRadius={20} />
                        </InputGroup>
                    </VStack>
                    <VStack pt='4%'>
                        <Text fontSize='20px' >Выберите какой подарок вы хотите</Text>
                        <RadioGroup onChange={setValue} value={value} w="100%" ml="5px" mb="8px" >
                            <Radio value="Два дизайна проекта" ml="10px">Два дизайна проекта</Radio>
                            <Radio value="Скидка 2000 руб" ml="10px">Скидка 2000 руб</Radio>
                        </RadioGroup>
                    </VStack>
                    <HStack gap="10px" pt='5%'>
                        <Button colorScheme="red" w="190px" p="25px" borderRadius={20} leftIcon={<AddIcon />} onClick={async () => await sendForm()}>Оставить заявку</Button>
                        <Link href="tel:+79876543210" _hover={{ textDecoration: 'none' }} onClick={() => {
                            window.ym(92057640, 'reachGoal', ' call_mobile_phone')
                            console.log('Цель метрики на нажатие телефона');
                        }}><Button colorScheme="blackAlpha" w="190px" p="25px" borderRadius={20} leftIcon={<PhoneIcon />}>Позвонить</Button></Link>
                    </HStack>
                </VStack>
            </Center>
        </Box>
    </HStack>
}